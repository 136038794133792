/** @format */

import React from "react";
import BrandLayout from "../../components/brandlayout/BrandLayout";
import { PastryCornerBrandArray } from "../../constants/PastryCornerBrandArray";
const PastryCorner = () => {
  return (
    <BrandLayout
      img={
        "https://d3u7b9fq2opvwp.cloudfront.net/upload-service/7a483b18-aeb4-4155-9713-cfdb216f56d4:FC%20Pastries12216.jpg"
      }
      brandName={"Pastry Corner"}
      igLink="https://www.instagram.com/lagosbreakfastclub"
      brandLongDesc={
        "Enjoy gourmet pastry bites perfectly crafted by seasoned pastry chefs. From your long time favorites of meat pies, doughnuts and cakes to crusty and soft croissants!"
      }
      brandArray={PastryCornerBrandArray}
    />
  );
};

export default PastryCorner;
