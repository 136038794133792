/** @format */

import React from 'react';
import Button from '../button';
import BrandCard from '../card/BrandCard';

const BrandLayoutContent = ({ brandName, igLink = '', brandArray, noIg }) => {
  return (
    <div className='py-16 sm:px-16'>
      <h3 className=' font-noirbold text-center flex justify-center mx-auto  w-[70%]  text-2xl md:text-3xl md:pb-6'>
        Get mouth watering dishes <br />
        from {brandName}
      </h3>

      <div className='py-10 gap-10 flex flex-wrap justify-center '>
        {brandArray.map((brand) => {
          return (
            <BrandCard
              meal={brand.meal}
              brandLogo={brand.brandLogo}
              description={brand.description}
              brandName={brand.brandName}
              link={brand.link}
              noIg={brand?.noIg}
              isBrandDetails
              restaurant={brand.restaurant}
            />
          );
        })}
      </div>

      {!noIg && (
        <div
          style={{
            backgroundImage: `url('https://res.cloudinary.com/olimeed/image/upload/v1667272961/instagram-bg_moktdo.png')`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
          }}
          className='md:my-16 transition-prop hover:bg-transparent hover:text-red-400 hover:shadow-md cursor-pointer py-9 md:rounded-[12px]  flex flex-col gap-10 justify-center items-center text-white-100 text-2xl md:text-3xl w-full md:w-[90%] lg:w-[70%] mx-auto'
        >
          <h2 className=' text-center text-black-200 '>
            Get meals updates from {brandName}
            <br /> on instagram.{' '}
          </h2>{' '}
          <a
            target={'_blank'}
            rel='noreferrer'
            href={igLink}
            onClick={() =>
              window.rudderanalytics.track('Restaurant Follow Us Click', {
                resturant: brandName,
              })
            }
            className='w-[90%] md:w-[70%] text-center'
          >
            <Button
              text={'Follow us'}
              height='h-[50px]'
              className={
                'bg-[#E60A2B] text-white-100 text-sm sm:text-lg w-[200px]  mx-auto hover:drop-shadow-xl rounded-[40px] text-manrope '
              }
            />
          </a>
        </div>
      )}
    </div>
  );
};

export default BrandLayoutContent;
