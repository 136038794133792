/** @format */

import React from "react";
import Slider from "react-slick";
import BrandCard from "../../components/card/BrandCard";
import { BrandsArray } from "../../constants/arrays";

const DesktopCarousel = ({ settings }) => {
	return (
		<div className="relative brand-image mx-auto w-[90%] h-[280px] flex justify-center items-center md:h-[720px]">
			<Slider {...settings}>
				<div className=" space-y-10 py-2 ">
					<BrandCard
						meal={BrandsArray[0].meal}
						brandLogo={BrandsArray[0].brandLogo}
						description={BrandsArray[0].description}
						brandName={BrandsArray[0].brandName}
						className={" mx-auto"}
						link={BrandsArray[0].link}
						noIg={BrandsArray[0]?.noIg}
					/>
					<BrandCard
						meal={BrandsArray[1].meal}
						brandLogo={BrandsArray[1].brandLogo}
						description={BrandsArray[1].description}
						brandName={BrandsArray[1].brandName}
						className={" mx-auto"}
						link={BrandsArray[1].link}
						noIg={BrandsArray[1]?.noIg}
					/>
				</div>
				<div className="space-y-10 py-2">
					<BrandCard
						meal={BrandsArray[2].meal}
						brandLogo={BrandsArray[2].brandLogo}
						description={BrandsArray[2].description}
						brandName={BrandsArray[2].brandName}
						className={" mx-auto"}
						link={BrandsArray[2].link}
						noIg={BrandsArray[2]?.noIg}
					/>
					<BrandCard
						meal={BrandsArray[4].meal}
						brandLogo={BrandsArray[4].brandLogo}
						description={BrandsArray[4].description}
						brandName={BrandsArray[4].brandName}
						className={" mx-auto"}
						link={BrandsArray[4].link}
						noIg={BrandsArray[4]?.noIg}
					/>
				</div>
				<div className="space-y-10 py-2">
					<BrandCard
						meal={BrandsArray[5].meal}
						brandLogo={BrandsArray[5].brandLogo}
						description={BrandsArray[5].description}
						brandName={BrandsArray[5].brandName}
						className={" mx-auto"}
						link={BrandsArray[5].link}
						noIg={BrandsArray[5]?.noIg}
					/>
					<BrandCard
						meal={BrandsArray[6].meal}
						brandLogo={BrandsArray[6].brandLogo}
						description={BrandsArray[6].description}
						brandName={BrandsArray[6].brandName}
						className={" mx-auto"}
						link={BrandsArray[6].link}
						noIg={BrandsArray[6]?.noIg}
					/>
				</div>
				<div className="space-y-10 py-2">
					<BrandCard
						meal={BrandsArray[7].meal}
						brandLogo={BrandsArray[7].brandLogo}
						description={BrandsArray[7].description}
						brandName={BrandsArray[7].brandName}
						className={" mx-auto"}
						link={BrandsArray[7].link}
						noIg={BrandsArray[7]?.noIg}
					/>
					<BrandCard
						meal={BrandsArray[8].meal}
						brandLogo={BrandsArray[8].brandLogo}
						description={BrandsArray[8].description}
						brandName={BrandsArray[8].brandName}
						className={" mx-auto"}
						link={BrandsArray[8].link}
						noIg={BrandsArray[8]?.noIg}
					/>
				</div>
			</Slider>
		</div>
	);
};

export default DesktopCarousel;
