/** @format */

export const MamasKitchenBrandArray = [
  {
    meal: 'http://res.cloudinary.com/cokitchen/image/upload/v1629809089/optimized_ovpt6r.jpg',
    description:
      "Mama's special egusi recipe. Served with choice of swallow & protein.",
    brandName: 'Egusi Soup',
    restaurant: 'Mamas Kitchen',
    link: 'https://link.getfoodcourt.com/brands/IlZfQrgQXZ8wHJ/vKW8dSBNP7e2Z31ab4dBOuZqPU7U3VkHVWrhLt56G7QNmR/AV/brand/IwBfReRDW5QwHJnrKG8dGkMU7eye3QGbstMc6JzcAOFlVECEWrhLt56G7QNmR/AV/menu/KFVeEe8VXMkwTMu7em8dSBJA7ezI2VWbstMf5craV+VgUxLVWrhLt56G7QNmR/AV/share',
  },
  {
    meal: 'http://res.cloudinary.com/cokitchen/image/upload/v1629808664/optimized_yzacj6.jpg',
    description: 'Classic afang soup, Served with choice of swallow & protein',
    brandName: 'Afang Soup',
    restaurant: 'Mamas Kitchen',
    link: 'https://link.getfoodcourt.com/brands/IlZfQrgQXZ8wHJ/vKW8dSBNP7e2Z31ab4dBOuZqPU7U3VkHVWrhLt56G7QNmR/AV/brand/IwBfReRDW5QwHJnrKG8dGkMU7eye3QGbstMc6JzcAOFlVECEWrhLt56G7QNmR/AV/menu/IlQNE+oRC80wHsyzL28dS0NF7bTIjgGbsoAc5MzfBuJoAE3YWrhLt56G7QNmR/AV/share',
  },
  {
    meal: 'http://res.cloudinary.com/cokitchen/image/upload/v1629808795/optimized_jylblw.jpg',
    description: 'Fully loaded seafood okra. Served with choice of swallow.',
    brandName: 'Seafood Okra',
    restaurant: 'Mamas Kitchen',
    link: 'https://link.getfoodcourt.com/brands/IlZfQrgQXZ8wHJ/vKW8dSBNP7e2Z31ab4dBOuZqPU7U3VkHVWrhLt56G7QNmR/AV/brand/IwBfReRDW5QwHJnrKG8dGkMU7eye3QGbstMc6JzcAOFlVECEWrhLt56G7QNmR/AV/menu/LwdfELoYCZowH5jrK28dGUhO7ezP2lSb5Y9O7p6OBrI3UBbQWrhLt56G7QNmR/AV/share',
  },
  {
    meal: 'http://res.cloudinary.com/cokitchen/image/upload/v1629809453/optimized_caax5s.jpg',
    description:
      "Mama's native ofada with rich ayamase sauce. Served with choice of protein & sides.",
    brandName: 'Ofada Rice',
    restaurant: 'Mamas Kitchen',
    link: 'https://link.getfoodcourt.com/brands/IlZfQrgQXZ8wHJ/vKW8dSBNP7e2Z31ab4dBOuZqPU7U3VkHVWrhLt56G7QNmR/AV/brand/IwBfReRDW5QwHJnrKG8dGkMU7eye3QGbstMc6JzcAOFlVECEWrhLt56G7QNmR/AV/menu/e1YFQugUX5UwGpi4KW8dHUVO7bed1VCb5tJH6prVU7ZpVRDQWrhLt56G7QNmR/AV/share',
  },
  {
    meal: 'http://res.cloudinary.com/cokitchen/image/upload/v1629810672/optimized_uqvntn.jpg',
    description:
      'Delicious native yam and potato porridge. Served with choice of protein.',
    brandName: 'Yam & Potato Porridge (Asaro)',
    restaurant: 'Mamas Kitchen',
    link: 'https://link.getfoodcourt.com/brands/IlZfQrgQXZ8wHJ/vKW8dSBNP7e2Z31ab4dBOuZqPU7U3VkHVWrhLt56G7QNmR/AV/brand/IwBfReRDW5QwHJnrKG8dGkMU7eye3QGbstMc6JzcAOFlVECEWrhLt56G7QNmR/AV/menu/KlNfFr0QCZ4wEJy7KW8dGBVC7bSS2AWb4dJP5MaIC+I0WkTXWrhLt56G7QNmR/AV/share',
  },
  {
    meal: 'http://res.cloudinary.com/cokitchen/image/upload/v1629810619/optimized_ytwxbi.jpg',
    description:
      'Delicious classic ewa agoyin, Served with choice of protein & sides',
    brandName: 'Ewa Agoyin',
    restaurant: 'Mamas Kitchen',
    link: 'https://link.getfoodcourt.com/brands/IlZfQrgQXZ8wHJ/vKW8dSBNP7e2Z31ab4dBOuZqPU7U3VkHVWrhLt56G7QNmR/AV/brand/IwBfReRDW5QwHJnrKG8dGkMU7eye3QGbstMc6JzcAOFlVECEWrhLt56G7QNmR/AV/menu/fwdZQeUUD5UwHJC9f28dRRNA7e2d3AebstVPuMvbVLRlVELRWrhLt56G7QNmR/AV/share',
  },
];
