/** @format */
import TNT1 from '../assets/img/Chicken Shawarma.jpg';
import TNT2 from '../assets/img/Chicken Suya Shawarma.jpg';
import TNT3 from '../assets/img/FC NT18775.jpg';
import TNT4 from '../assets/img/Chicken Suya Shawarma.jpg';
import TNT5 from '../assets/img/Classic Mixed Shawarma.jpg';
import TNT6 from '../assets/img/Chicken Suya Shawarma.jpg';
export const TheNightTrainBrandArray = [
  {
    meal: TNT1,
    description: 'Classic chicken shawarma with veg & special sauce.',
    brandName: 'Chicken Shawarma ',
    restaurant: 'The Night Train',
    link: 'https://link.getfoodcourt.com/brands/IlZfQrgQXZ8wHJ/vKW8dSBNP7e2Z31ab4dBOuZqPU7U3VkHVWrhLt56G7QNmR/AV/brand/flFYRuwTXZ8wGs3pfG8dREBC7e3P2lCbsdVI6s3dBOBoWkbQWrhLt56G7QNmR/AV/menu/eQIJEeQZXs8wGZu4K28dGEdO7e3L2Aebto5G6MbcB7VnUReCWrhLt56G7QNmR/AV/share',
  },
  {
    meal: TNT2,
    description:
      'Special mixed beef & chicken suya shawarma with veg & special sauce.',
    brandName: 'Mixed Suya Shawarma',
    restaurant: 'The Night Train',
    link: 'https://link.getfoodcourt.com/brands/IlZfQrgQXZ8wHJ/vKW8dSBNP7e2Z31ab4dBOuZqPU7U3VkHVWrhLt56G7QNmR/AV/brand/flFYRuwTXZ8wGs3pfG8dREBC7e3P2lCbsdVI6s3dBOBoWkbQWrhLt56G7QNmR/AV/menu/eABeReQXUpwwEM3ve28dTUYV7e3IjQCb54Ifus/YA7FhARbXWrhLt56G7QNmR/AV/share',
  },
  {
    meal: TNT3,
    description:
      'Delicious party style smokey jollof rice with choice of protein & sides.',
    brandName: 'Smokey Jollof Rice',
    restaurant: 'The Night Train',
    link: 'https://link.getfoodcourt.com/brands/IlZfQrgQXZ8wHJ/vKW8dSBNP7e2Z31ab4dBOuZqPU7U3VkHVWrhLt56G7QNmR/AV/brand/flFYRuwTXZ8wGs3pfG8dREBC7e3P2lCbsdVI6s3dBOBoWkbQWrhLt56G7QNmR/AV/menu/fFIJRepHUs4wSsy5fG8dSUdA7e2YiQCbsoAa5c/YCuFkW0XUWrhLt56G7QNmR/AV/share',
  },
  {
    meal: TNT4,
    description:
      'The great meat lovers shawarma loaded with beef brisket, smoked beef, chopped sausage & pepperoni.',
    brandName: 'Meat Lovers Shawarma',
    restaurant: 'The Night Train',
    link: 'https://link.getfoodcourt.com/brands/IlZfQrgQXZ8wHJ/vKW8dSBNP7e2Z31ab4dBOuZqPU7U3VkHVWrhLt56G7QNmR/AV/brand/flFYRuwTXZ8wGs3pfG8dREBC7e3P2lCbsdVI6s3dBOBoWkbQWrhLt56G7QNmR/AV/menu/eQEFELkQDJkwGp/rLm8dSBUV7bTO1FCbvINP6svYBrVhVETWWrhLt56G7QNmR/AV/share',
  },
  {
    meal: TNT5,
    description:
      'Classic mixed beef & chicken shawarma with veg & special sauce.',
    brandName: 'Classic Mixed Shawarma',
    restaurant: 'The Night Train',
    link: 'https://link.getfoodcourt.com/brands/IlZfQrgQXZ8wHJ/vKW8dSBNP7e2Z31ab4dBOuZqPU7U3VkHVWrhLt56G7QNmR/AV/brand/flFYRuwTXZ8wGs3pfG8dREBC7e3P2lCbsdVI6s3dBOBoWkbQWrhLt56G7QNmR/AV/menu/LQJdQesWW5swT5m8Lm8dTBJH7ezJiVObsYJL652NV7NpUUCEWrhLt56G7QNmR/AV/share',
  },
  {
    meal: TNT6,
    description:
      'Special chicken shawarma with grilled chicken suya, veg & special sauce. ',
    brandName: 'Chicken Suya Shawarma',
    restaurant: 'The Night Train',
    link: 'https://link.getfoodcourt.com/brands/IlZfQrgQXZ8wHJ/vKW8dSBNP7e2Z31ab4dBOuZqPU7U3VkHVWrhLt56G7QNmR/AV/brand/flFYRuwTXZ8wGs3pfG8dREBC7e3P2lCbsdVI6s3dBOBoWkbQWrhLt56G7QNmR/AV/menu/KQVeFr5ADpowGpjoK28dSUdE7eyS2labt9Md7c6OBeNoAE2HWrhLt56G7QNmR/AV/share',
  },
];
