/** @format */

import React from "react";
import { Link } from "react-router-dom";
import Button from "../button";
import HelpBg from "../../assets/img/help-bg.png";
import { CONTACT } from "../../routes";
const LocationLayoutFooter = () => {
	return (
		<>
		<div
			className="w-full sm:w-[85%] mx-auto text-center flex flex-col justify-center items-center sm:rounded-[30px] gap-10 sm:mb-32"
			style={{
				backgroundImage: `url(${HelpBg})`,
				backgroundSize: "cover",
				backgroundRepeat: "no-repeat",
				backgroundPosition: "center",
				height: "400px",
			}}
		>
			<h2 className=" text-black-400 font-noirmedium text-2xl md:text-3xl">
				Need help ?
			</h2>
			<p className=" text-sm sm:text-lg md:text-xl md:leading-9 w-[70%] text-black-40">
				Got questions or concerns about our service, send us a message and we
				will get back to you soonest{" "}
			</p>
			<Link to={CONTACT}>
				<Button
					text={"Contact us"}
					height="h-[40px]"
					className={

						"bg-red-400 text-white-100 text-sm sm:text-lg w-[200px] mx-auto   rounded-[40px] text-manrope px-4"

					}
				/>
			</Link>
			
		</div>
		<p className="mx-auto text-grey-100 mb-28 md:mt-0 mt-28 text-sm sm:text-lg md:text-xl md:leading-9 md:w-[70%] w-[90%] text-center">
        Surrounded by Ikoyi, Elegushi and Victoria Island, Lekki Phase 1 is one
        of the most affluent neighborhoods in Lagos.Popularly known as a home
        for high income earning Lagosians, the Lekki Phase 1 residential scheme
        was established by the government of Lagos State through the famous
        Lagos State Property Development Agency. The area also accommodates one
        of the busiest streets in Lagos, Admiralty Way, where many businesses in
        Lagos have their headquarters. Lekki Phase 1 is equally blessed with
        lots of local and Continental Restaurants which offer cuisines from all
        parts of the world. To make it even easier, getfoodcourt offers everyone
        the opportunity to browse through the best restaurants in Lekki Phase 1
        and the entire Peninsula as well as the neighboring Victoria Island,
        Ikoyi and Elegushi.
      </p></>
		
	);
};

export default LocationLayoutFooter;
