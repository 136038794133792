/** @format */

import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { BRANDS, CONTACT, FAQS } from '../../routes';

const NavMenu = ({ isStickyNav, isWhite, isEmail }) => {
  const { pathname } = useLocation();
  return (
    <div
      className={`hidden md:flex text-base mdLg:text-xl gap-8 font-semibold items-center`}
    >
      <NavLink
        className={`mt-1 ${
          pathname === BRANDS
            ? 'text-red-400'
            : isStickyNav || isWhite || isEmail
            ? 'text-black-300'
            : 'text-white-100'
        }`}
        to={BRANDS}
      >
        {' '}
        Restaurants{' '}
      </NavLink>
      <NavLink
        className={` mt-1 ${
          pathname === FAQS
            ? 'text-red-400'
            : isStickyNav || isWhite || isEmail
            ? 'text-black-300'
            : 'text-white-100'
        }`}
        to={FAQS}
      >
        {' '}
        FAQs{' '}
      </NavLink>
      <NavLink
        className={`mt-1 ${
          pathname === CONTACT
            ? 'text-red-400'
            : isStickyNav || isWhite || isEmail
            ? 'text-black-300'
            : 'text-white-100'
        }`}
        to={CONTACT}
      >
        {' '}
        Support{' '}
      </NavLink>
    </div>
  );
};

export default NavMenu;

// text-white-100 ${
//   isStickyNav || isWhite
//     ? "text-black-300"
//     : pathname === BRANDS && "text-red-400"
// }
