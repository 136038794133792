/** @format */

import React from 'react';
import { Link } from 'react-router-dom';
import { Socials } from '../../constants/arrays';
import WhiteLogo from '../../assets/img/Logo-White.png';
import {
  CHOW_ASIAN,
  FAQS,
  FC_SHOP,
  FRANKIES,
  IKOYI,
  JOLLOF_CO,
  LEKKI,
  SUYA_CENTRAL,
  FRESH_PRESS,
  RED_PEPPER,
  GREEN_GOURMET,
  LAGOS_BREAKFAST_CLUB,
  THE_NIGHT_TRAIN,
  PASTRY_CORNER,
  MAMAS_KITCHEN,
  MARINA,
  SUNNY_SIDE_CLUB,
  SUPPORT,
  SWEET_TREATS,
  USER_POLICY,
  VI,
  WINGS_KINGS,
  WRAP_CITY,
} from '../../routes';

const Footer = () => {
  const currentYear = new Date().getFullYear();

  const restaurants1 = [
    {
      name: 'Wing kings',
      link: WINGS_KINGS,
    },
    {
      name: 'Jollof & Co',
      link: JOLLOF_CO,
    },
    {
      name: 'Sweet Treats',
      link: SWEET_TREATS,
    },
    {
      name: 'Sunny Side Club',
      link: SUNNY_SIDE_CLUB,
    },
    {
      name: 'Mama’s Kitchen',
      link: MAMAS_KITCHEN,
    },
    {
      name: 'Frankies',
      link: FRANKIES,
    },
    {
      name: 'CHōW Asian',
      link: CHOW_ASIAN,
    },
    {
      name: 'Wrap City',
      link: WRAP_CITY,
    },
  ];

  const restaurants2 = [
    {
      name: 'Good Greens',
      link: GREEN_GOURMET,
    },
    {
      name: 'Suya Central',
      link: SUYA_CENTRAL,
    },
    {
      name: 'Pastry Corner',
      link: PASTRY_CORNER,
    },
    {
      name: 'Fresh Press',
      link: FRESH_PRESS,
    },
    {
      name: 'Lagos Breakfast Club',
      link: LAGOS_BREAKFAST_CLUB,
    },
    {
      name: 'The Night Train',
      link: THE_NIGHT_TRAIN,
    },
    {
      name: 'Red Pepper',
      link: RED_PEPPER,
    },
  ];

  return (
    <div className='bg-black-100 p-10 sm:p-24 lg:px-[5rem] text-[#AAAEBB]'>
      <div className='flex flex-wrap justify-between gap-16'>
        <div className='flex flex-col gap-3 w-[150px] md:w-fit '>
          <img
            src={WhiteLogo}
            className='w-32 sm:w-36 mb-[18px]'
            alt='Best Food Delivery App In Lagos Island, Lekki, Victoria Island - FoodCourt Logo'
          />
          <h4 className='ml-1 text-lg sm:text-xl  text-white-100 '>Contact</h4>
          <a
            className='ml-1transition-prop hover:underline hover:underline-offset-2 hover:text-blue-400 text-base md:text-lg'
            href='mailto:hello@getfoodcourt.com'
            onClick={() =>
              window.rudderanalytics.track('Contact With Us Email Clicked')
            }
          >
            hello@getfoodcourt.com
          </a>
          <a
            className=' transition-prop hover:underline hover:underline-offset-2 hover:text-blue-400 text-base md:text-lg'
            href='tel:+234 2112 417 212'
            onClick={() =>
              window.rudderanalytics.track('Call Us Phone Number Clicked')
            }
          >
            +234 1 888 0265
          </a>
        </div>
        <div className='flex flex-col gap-2 w-[150px] md:w-fit '>
          <h4 className='text-lg sm:text-xl text-white-100'>Company</h4>

          <a
            className=' transition-prop hover:underline hover:underline-offset-2 hover:text-blue-400 text-base md:text-lg'
            href='mailto:careers@getfoodcourt.com'
          >
            Careers
          </a>
          <Link
            to={FAQS}
            className=' transition-prop hover:underline hover:underline-offset-2 hover:text-blue-400 text-base md:text-lg'
          >
            {' '}
            FAQs
          </Link>
          <Link
            to={USER_POLICY}
            className=' transition-prop hover:underline hover:underline-offset-2 hover:text-blue-400 text-base md:text-lg '
          >
            {' '}
            User Policy
          </Link>
          <Link
            to={SUPPORT}
            className=' transition-prop hover:underline hover:underline-offset-2 hover:text-blue-400 text-base md:text-lg '
          >
            Support
          </Link>
        </div>
        <div className='flex flex-col gap-3 w-[150px] md:w-fit '>
          <h4 className='text-lg sm:text-xl  text-white-100'>Locations</h4>
          <Link
            to={LEKKI}
            className=' transition-prop hover:underline hover:underline-offset-2 hover:text-blue-400 text-base md:text-lg'
          >
            {' '}
            Lekki
          </Link>
          <Link
            to={IKOYI}
            className=' transition-prop hover:underline hover:underline-offset-2 hover:text-blue-400 text-base md:text-lg'
          >
            {' '}
            Ikoyi
          </Link>
          <Link
            to={VI}
            className=' transition-prop hover:underline hover:underline-offset-2 hover:text-blue-400 text-base md:text-lg'
          >
            {' '}
            Victoria Island
          </Link>
          <Link
            to={MARINA}
            className=' transition-prop hover:underline hover:underline-offset-2 hover:text-blue-400 text-base md:text-lg '
          >
            {' '}
            Marina
          </Link>
        </div>

        <div className='flex flex-col gap-2 w-[150px] md:w-fit '>
          <h4 className='text-lg sm:text-xl ml-12 text-white-100'>
            Restaurants
          </h4>
          <div className='flex flex-wrap gap-3'>
            <div className='flex flex-col gap-3'>
              {restaurants1.map((item, index) => (
                <Link
                  key={index}
                  to={item.link}
                  className=' transition-prop hover:underline hover:underline-offset-2 hover:text-blue-400 text-base md:text-lg'
                >
                  {item.name}
                </Link>
              ))}
            </div>
            <div className='flex flex-col gap-3 md:ml-4'>
              {restaurants2.map((item, index) => (
                <Link
                  key={index}
                  to={item.link}
                  className=' transition-prop hover:underline hover:underline-offset-2 hover:text-blue-400 text-base md:text-lg'
                >
                  {item.name}
                </Link>
              ))}
            </div>
          </div>
        </div>
        <div className='flex flex-col gap-3  '>
          <h4 className='text-lg sm:text-xl  text-white-100'>Socials</h4>
          <div className='flex gap-6 sm:gap-4'>
            {Socials.map((social, i) => {
              return (
                <a
                  href={`${social.link}`}
                  rel='noreferrer'
                  target={'_blank'}
                  onClick={() =>
                    window.rudderanalytics.track(
                      'Social Media Button Clicked',
                      {
                        social_media_platform: social.name,
                      }
                    )
                  }
                  key={i}
                >
                  <img
                    src={social.img}
                    alt={`FoodCourt - ${social.link}`}
                    className='w-6 hover:scale-[.85] transition-prop '
                  />
                </a>
              );
            })}
          </div>
        </div>
      </div>

      <p className='w-[250px] mt-16 sm:mt-20 sm:w-auto text-sm text-white-100 opacity-50 font-noir leading-7'>
        © {currentYear} CoKitchen Workspace Limited All - rights reserved.
      </p>
    </div>
  );
};

export default Footer;
