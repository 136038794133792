/** @format */

import React from 'react';
import BrandLayout from '../../components/brandlayout/BrandLayout';
import { WrapCityBrandArray } from '../../constants/WrapCityBrandArray';
const WrapCity = () => {
  return (
    <BrandLayout
      img={
        'https://res.cloudinary.com/olimeed/image/upload/q_auto:good/v1666863214/Wrap_City_kfbafu.png'
      }
      brandName={'Wrap City'}
      igLink=''
      brandLongDesc={
        'Experience the widest range of Shawarma flavors here.  Spiced up, juicy and well seasoned - just how you like it! Exclusively on FoodCourt.'
      }
      brandArray={WrapCityBrandArray}
      noIg
    />
  );
};

export default WrapCity;
