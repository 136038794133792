/** @format */

export const ChowAsianArray = [
  {
    meal: 'http://res.cloudinary.com/cokitchen/image/upload/v1650819240/pa8vzgk8rpgjkcenalod.jpg',
    description:
      'Delicious fully loaded fried rice with bits of chicken, prawn, sausages & mixed veg. Served with your choice of sauce.',
    brandName: 'Special Fried Rice ',
    restaurant: 'Chow Asian',
    link: 'https://link.getfoodcourt.com/brands/IlZfQrgQXZ8wHJ/vKW8dSBNP7e2Z31ab4dBOuZqPU7U3VkHVWrhLt56G7QNmR/AV/brand/e1AFE+lCD80wH8q+em8dTBFB7bSSilWb59NGuJ2IB+U0UhHVWrhLt56G7QNmR/AV/menu/L1taTL8VW8gwSJuzK28dRUQS7e2c21Cbt9BLvc7eU+QyBkLWWrhLt56G7QNmR/AV/share',
  },
  {
    meal: 'http://res.cloudinary.com/cokitchen/image/upload/v1650818753/warkvixxgyqtt9zkw5gt.jpg',
    description:
      'Deep fried battered calamari paired paired with dipping sauce of choice.',
    brandName: 'Crispy Calamari ',
    restaurant: 'Chow Asian',
    link: 'https://link.getfoodcourt.com/brands/IlZfQrgQXZ8wHJ/vKW8dSBNP7e2Z31ab4dBOuZqPU7U3VkHVWrhLt56G7QNmR/AV/brand/e1AFE+lCD80wH8q+em8dTBFB7bSSilWb59NGuJ2IB+U0UhHVWrhLt56G7QNmR/AV/menu/L1MIRuwUXJUwHpvrLG8dShUU7ezL3VGbt4NIuMuPA+VgVkCCWrhLt56G7QNmR/AV/share',
  },
  {
    meal: 'http://res.cloudinary.com/cokitchen/image/upload/v1650819267/r6caruhamu3hhlkoc3jj.jpg',
    description:
      'Fried rice with minced beef & mixed veg. Served with your choice of sauce.',
    brandName: 'Beef Fried Rice ',
    restaurant: 'Chow Asian',
    link: 'https://link.getfoodcourt.com/brands/IlZfQrgQXZ8wHJ/vKW8dSBNP7e2Z31ab4dBOuZqPU7U3VkHVWrhLt56G7QNmR/AV/brand/e1AFE+lCD80wH8q+em8dTBFB7bSSilWb59NGuJ2IB+U0UhHVWrhLt56G7QNmR/AV/menu/LFsMQOQSU5QwT8voL28dTUBA7eyfjlabsoRK6cqOVLNhVk2HWrhLt56G7QNmR/AV/share',
  },
  {
    meal: 'http://res.cloudinary.com/cokitchen/image/upload/v1650818700/ymskgskrizk0odzbejrt.jpg',
    description:
      'Fully loaded chow mein noodles with chicken, prawn, sausages & mixed veg. Served with choice of sauce.',
    brandName: 'Special Chow Mein Noodles ',
    restaurant: 'Chow Asian',
    link: 'https://link.getfoodcourt.com/brands/IlZfQrgQXZ8wHJ/vKW8dSBNP7e2Z31ab4dBOuZqPU7U3VkHVWrhLt56G7QNmR/AV/brand/e1AFE+lCD80wH8q+em8dTBFB7bSSilWb59NGuJ2IB+U0UhHVWrhLt56G7QNmR/AV/menu/eFANR7lDWckwS5m4em8dTUAS7bSZ3gab5oBN68vdAe9jW0bYWrhLt56G7QNmR/AV/share',
  },
  {
    meal: 'http://res.cloudinary.com/cokitchen/image/upload/v1650818742/beojxjww1isc5bq4qpew.jpg',
    description:
      'Crispy deep fried spring rolls stuffed with prawn & mayonnaise + dipping sauce of choice',
    brandName: 'Prawn Mayo Spring Rolls',
    restaurant: 'Chow Asian',
    link: 'https://link.getfoodcourt.com/brands/IlZfQrgQXZ8wHJ/vKW8dSBNP7e2Z31ab4dBOuZqPU7U3VkHVWrhLt56G7QNmR/AV/brand/e1AFE+lCD80wH8q+em8dTBFB7bSSilWb59NGuJ2IB+U0UhHVWrhLt56G7QNmR/AV/menu/eFRfQ78YCMgwGpiyKW8dSRJP7bSc3lqbt4BH6sreU7RiU0TSWrhLt56G7QNmR/AV/share',
  },
  {
    meal: 'http://res.cloudinary.com/cokitchen/image/upload/v1650819142/limh8zwboxg6hu8xsvzv.jpg',
    description:
      'Singapore stir fried noodles with bits of chicken and mixed veg. Served with your choice of sauce.',
    brandName: 'Chicken Singapore Noodles ',
    restaurant: 'Chow Asian',
    link: 'https://link.getfoodcourt.com/brands/IlZfQrgQXZ8wHJ/vKW8dSBNP7e2Z31ab4dBOuZqPU7U3VkHVWrhLt56G7QNmR/AV/brand/e1AFE+lCD80wH8q+em8dTBFB7bSSilWb59NGuJ2IB+U0UhHVWrhLt56G7QNmR/AV/menu/KAFZEOhEX88wSMizLm8dRUMS7e2f3wGb4dNK7svcBbRlWhGHWrhLt56G7QNmR/AV/share',
  },
];
