/** @format */

import React, { useEffect, useState } from 'react';
import Burger from './Burger';
import WhiteLogo from '../../assets/img/Logo-White.png';
import RedLogo from '../../assets/img/Logo_Red.png';

import Button from '../button';
import { Link } from 'react-router-dom';
import { LANDING } from '../../routes';
import Navbar from './Navbar';
import NavMenu from './NavMenu';

const Header = ({ isWhite, isEmail }) => {
  const [openNav, setOpenNav] = useState(false);
  const [isStickyNav, setisStickyNav] = useState(false);

  function toggleNav() {
    setOpenNav(!openNav);
  }

  useEffect(() => {
    window.addEventListener('scroll', isSticky);
    return () => {
      window.removeEventListener('scroll', isSticky);
    };
  }, []);

  /* Position filter to the top after scroll */
  const isSticky = (e) => {
    setisStickyNav(true);
    const header = document.querySelector('.header-section');
    const scrollTop = window.scrollY;
    scrollTop >= 20
      ? header.classList.add('is-sticky')
      : header.classList.remove('is-sticky');
    if (window.scrollY >= 20) {
      setisStickyNav(true);
    } else {
      setisStickyNav(false);
    }
  };

  const handleClick = () => {
    window.rudderanalytics.track('App Install Click', {
      app_type: 'ios or Android',
    });
  };

  return (
    <div
      className={`z-[100000] ${
        isWhite || openNav ? 'bg-white-100' : isEmail ? 'bg-[#F7F7F7]' : ''
      } header-section fixed w-full`}
    >
      <div
        className='flex justify-between py-6 space-x-4 
			 pl-4 pr-4 sm:pl-10 sm:pr-20'
      >
        <div className='flex space-x-16 items-center'>
          <Link to={LANDING}>
            {isStickyNav || isWhite || openNav || isEmail ? (
              <img
                src={RedLogo}
                className='w-32 sm:w-36'
                alt='Best Food Delivery App In Lagos Island, Lekki, Victoria Island - FoodCourt Logo'
              />
            ) : (
              <img
                src={WhiteLogo}
                className='w-32 sm:w-36'
                alt='Best Food Delivery App In Lagos Island, Lekki, Victoria Island - FoodCourt Logo'
              />
            )}
          </Link>
          <NavMenu
            isStickyNav={isStickyNav}
            isWhite={isWhite}
            isEmail={isEmail}
          />
        </div>
        <a
          href='https://foodcourt.onelink.me/hRlV/Fcweb'
          target='_blank'
          rel='noreferrer'
          onClick={handleClick}
          className='sm:block hidden'
        >
          <Button
            text='Get the App!'
            height={' h-8 sm:h-10 '}
            className={`hidden md:flex font-semibold    font-noirmedium  text-base px-4 sm:px-8 ${
              isStickyNav || isWhite
                ? ' shadow-2xl bg-red-400 text-white-100'
                : ' bg-white-100 text-red-400'
            }`}
            isRounded
          />
        </a>
        <Burger
          openNav={openNav}
          toggleNav={toggleNav}
          isStickyNav={isStickyNav}
          isWhite={isWhite}
        />
      </div>

      <Navbar openNav={openNav} isStickyNav={isStickyNav} />
    </div>
  );
};

export default Header;
