/** @format */

export const SweetTreatsBrandArray = [
  {
    meal: 'http://res.cloudinary.com/cokitchen/image/upload/v1656451586/ianynzrhoa3kge8m60of.jpg',
    description:
      'Waffles topped with seasonal mixed berries & choice of drizzle',
    brandName: 'Waffles x Mixed Berries ',
    restaurant: 'Sweet Treats',
    link: 'https://link.getfoodcourt.com/brands/IlZfQrgQXZ8wHJ/vKW8dSBNP7e2Z31ab4dBOuZqPU7U3VkHVWrhLt56G7QNmR/AV/brand/KwBaR+gYCZUwSsizfW8dTkcW7e2b3leb4NRO7ZzUUeBjUkPRWrhLt56G7QNmR/AV/menu/eQddQbpCD5kwHp25dW8dGkIU7bSbiFGbtocd6cvbU+Y1BkzTWrhLt56G7QNmR/AV/share',
  },
  {
    meal: 'http://res.cloudinary.com/cokitchen/image/upload/v1656452247/sp9ktgtx4d7kucnbg0dw.jpg',
    description:
      'Waffles topped with crushed Oreo cookies & choice of drizzle.',
    brandName: 'Waffles x Crushed Oreos',
    restaurant: 'Sweet Treats',
    link: 'https://link.getfoodcourt.com/brands/IlZfQrgQXZ8wHJ/vKW8dSBNP7e2Z31ab4dBOuZqPU7U3VkHVWrhLt56G7QNmR/AV/brand/KwBaR+gYCZUwSsizfW8dTkcW7e2b3leb4NRO7ZzUUeBjUkPRWrhLt56G7QNmR/AV/menu/ewFfRepFWpowT8vofG8dH0dA7ezI2FGb4INL5MiJVu5lARfZWrhLt56G7QNmR/AV/share',
  },
  {
    meal: 'http://res.cloudinary.com/cokitchen/image/upload/v1656451955/sortep5gchwukqwk0htx.jpg',
    description:
      'Pancakes topped with sliced strawberries & banana with choice of drizzle.',
    brandName: 'Pancakes x Strawberries & Banana',
    restaurant: 'Sweet Treats',
    link: 'https://link.getfoodcourt.com/brands/IlZfQrgQXZ8wHJ/vKW8dSBNP7e2Z31ab4dBOuZqPU7U3VkHVWrhLt56G7QNmR/AV/brand/KwBaR+gYCZUwSsizfW8dTkcW7e2b3leb4NRO7ZzUUeBjUkPRWrhLt56G7QNmR/AV/menu/fgYEQO5EWJwwT5i8L28dSRND7befiFqb4IdPvsvUAOU3BBCEWrhLt56G7QNmR/AV/share',
  },
  {
    meal: 'http://res.cloudinary.com/cokitchen/image/upload/v1656451850/a7dt0h4i1w9d5qgen3b4.jpg',
    description: 'Pancakes topped with sliced bananas & choice of drizzle.',
    brandName: 'Pancakes x Banana',
    restaurant: 'Sweet Treats',
    link: 'https://link.getfoodcourt.com/brands/IlZfQrgQXZ8wHJ/vKW8dSBNP7e2Z31ab4dBOuZqPU7U3VkHVWrhLt56G7QNmR/AV/brand/KwBaR+gYCZUwSsizfW8dTkcW7e2b3leb4NRO7ZzUUeBjUkPRWrhLt56G7QNmR/AV/menu/IlBZQOwXDs0wT5roK28dREAV7bSZ2FebtY9M5cePALNhUELXWrhLt56G7QNmR/AV/share',
  },
  {
    meal: 'http://res.cloudinary.com/cokitchen/image/upload/v1656451703/ufadtikrypw34qlw2rdo.jpg',
    description:
      'French Toast topped with sliced strawberries & choice of drizzle.',
    brandName: 'French Toast x Strawberries',
    restaurant: 'Sweet Treats',
    link: 'https://link.getfoodcourt.com/brands/IlZfQrgQXZ8wHJ/vKW8dSBNP7e2Z31ab4dBOuZqPU7U3VkHVWrhLt56G7QNmR/AV/brand/KwBaR+gYCZUwSsizfW8dTkcW7e2b3leb4NRO7ZzUUeBjUkPRWrhLt56G7QNmR/AV/menu/flAPTb4WCJQwSpC4KW8dThRG7bTL3geb5oJP6MeKBbNoUBKAWrhLt56G7QNmR/AV/share',
  },
  {
    meal: 'http://res.cloudinary.com/cokitchen/image/upload/v1656452010/aljpcwrgzgzd7dkrhaas.jpg',
    description:
      'Crêpes topped with crushed Oreo cookies & choice of drizzle. ',
    brandName: 'Crêpes x Crushed Oreos',
    restaurant: 'Sweet Treats',
    link: 'https://link.getfoodcourt.com/brands/IlZfQrgQXZ8wHJ/vKW8dSBNP7e2Z31ab4dBOuZqPU7U3VkHVWrhLt56G7QNmR/AV/brand/KwBaR+gYCZUwSsizfW8dTkcW7e2b3leb4NRO7ZzUUeBjUkPRWrhLt56G7QNmR/AV/menu/fFYOTL0QUpgwGcy+KG8dGUcR7e2T3FKbs4NN78/VA+5nWhHYWrhLt56G7QNmR/AV/share',
  },
];
