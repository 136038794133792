import React from 'react'
import { ReactComponent as EmailProgressIcon } from '../../assets/icons/email-progress.svg'
import { ReactComponent as EmailSuccessIcon } from '../../assets/icons/email-success.svg'
import { ReactComponent as EmailFailedIcon } from '../../assets/icons/email-failed.svg'
import Button from '../../components/button'
import { Link } from 'react-router-dom'
import { SUPPORT } from '../../routes'

const EmailStatus = ({ status }) => {
  return (
    <div className={`flex flex-col h-[100%] justify-center items-center pt-36`}>
      {
        status === 0 ? <EmailProgressIcon className='h-[100px] w-[140px]' /> : status === 1 ? <EmailSuccessIcon className='h-[100px] w-[140px]' /> : <EmailFailedIcon className='h-[100px] w-[140px]' />
      }

      <h5 className=' font-noirmedium text-xl sm:text-3xl md:text-[40px]'>
        {status === 0 && 'Email Verification in Progress'}
        {status === 1 && 'Email Verified Successfully'}
        {status === 2 && 'Email Verification Error '}
      </h5>

      <p className=' text-[#858585] mt-4 text-base sm:text-xl sm:w-[400px] text-center'>
        {status === 0 && 'Your email is currently being reviewed, you’ll be notified once verified.'}
        {status === 1 && 'Your email has been verified, proceed to the app to get your meal!'}
        {status === 2 && 'We were unable to verify your email address, contact support to resolve.'}

      </p>
      {
        status === 2 && <Link to={SUPPORT}>
          <Button
            text="Contact Support"
            height={"h-11"}
            className={`mt-10 flex font-manrope font-medium text-base px-8 md:px-14 shadow-2xl bg-red-400 text-white-100`}
            isRounded
          />
        </Link>
      }
      {
        status === 1 && <a rel='noreferrer' target='_blank' href='http://link.getfoodcourt.com/'>
          <Button
            text="Open your App"
            height={"h-11"}
            className={`mt-10 flex font-manrope font-medium text-base px-8 md:px-14 shadow-2xl bg-red-400 text-white-100`}
            isRounded
          />
        </a>
      }
    </div>
  )
}

export default EmailStatus