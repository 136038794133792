/** @format */

import React from 'react';
import BrandCard from '../../components/card/BrandCard';
import Header from '../../components/header';
import GetApp from '../landing/GetApp';
import Footer from '../../components/footer';
import { BrandsArray } from '../../constants/arrays';
import Meta from '../../components/meta/Meta';

const MeetTheBrands = () => {
  return (
    <div>
      <Meta
        title='FoodCourt || Brands'
        url='https://www.getfoodcourt.com/brands'
        description='Everyday naija Jollof,Fried Rice, Good chicken & more, Delicious & authentic Asian flavours, Breakfast to start your day right, Mama’s special local dishes, Gourmet burgers. hotdogs & more!, Delicious shawarma, wraps & more!, Snacks, liquor, essentials & more! Food On The Island, Food In VI, Food In Lekki'
      />
      <Header isWhite />

      <div className='pt-28 mx-auto sm:px-16 '>
        <div className='text-left text-grey-100 space-y-2'>
          <h3 className='mx-auto text-grey-100 text-lg sm:text-2xl md:text-3xl font-noirbold text-center'>
            Meet the restaurants
          </h3>

          <p className='text-sm sm:text-base text-manrope text-center'>
            A curation of the best on-demand delivery restaurants{' '}
          </p>
        </div>
        <div className='py-10 gap-10 flex flex-wrap align-center justify-center '>
          {BrandsArray.map((brand) => {
            return (
              <BrandCard
                meal={brand.meal}
                brandLogo={brand.brandLogo}
                description={brand.description}
                brandName={brand.brandName}
                link={brand.link}
                noIg={brand?.noIg}
              />
            );
          })}
        </div>
      </div>
      <div className='md:pb-36 xs:pb-16 md:pt-24'>
        <GetApp />
      </div>

      <Footer />
    </div>
  );
};

export default MeetTheBrands;
