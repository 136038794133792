/** @format */

import React from 'react';
import { useState, useEffect } from 'react';
import { browserName } from 'react-device-detect';
import Header from '../../components/header';
import Footer from '../../components/footer';
import Meta from '../../components/meta/Meta';
import { Input, TextArea } from '../../components/inputs';
import GetApp from '../landing/GetApp';
import Button from '../../components/button';
import DataService from '../../services/support';
import UserEmailReceived from '../../components/user-email-received/UserEmailReceived';
const Contact = () => {
  const [safari, setSafari] = useState(null);
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  useEffect(() => {
    if (browserName.includes('Safari')) {
      setSafari(true);
    } else {
      setSafari(false);
    }
  }, [safari]);

  const hideConfirm = () => setTimeout(() => setShowConfirm(false), 7000);

  useEffect(() => {
    hideConfirm();
  }, [showConfirm]);

  const submitQuestion = async () => {
    setLoading(true);
    window.rudderanalytics.track('Contact Us Form Submitted', {
      email: email,
    });
    let payload = {
      email: email,
      message: message,
    };

    let res = await DataService.postInternalData(
      'internal/email/support',
      payload
    );
    if (res) {
      setEmail('');
      setMessage('');
      setShowConfirm(true);
    }
    setLoading(false);
  };
  return (
    <div>
      <div className='relative'>
        <Meta
          title='FoodCourt Contact - FoodCourt Help'
          url='https://www.getfoodcourt.com/contact-us'
          description='What is FoodCourt?. How do I order? Can I order from different restaurants? How do I top up my wallet? Can I earn on FoodCourt? How do I delete or edit orders in my basket? How do I delete or edit orders in my basket? How can I report or resolve issues & disputes? How can I report or resolve issues & disputes? What is the delivery fee? What are the available payment options? What are the opening hours? How do group orders work? How do I become a delivery partner? Can i get a refund? Can I Get Food On The Island, Food In VI, Food In Lekki'
        />
        <Header isWhite />
        <div className='pt-24 mdLg:pt-32 py-10 relative '>
          <div className=' lg:px-35 px-4 md:px-48 md:mx-24 py-24 md:py-16 lg:mx-36 bg-gradient-reverse text-white-100 '>
            <div className='mx-auto text-center space-y-10 '>
              <h3 className='text-2xl md:text-4xl font-noirbold '>
                Contact Our Support{' '}
              </h3>
              <p className='text-base md:text-xl  lg:px-20  '>
                Got questions or concerns about our service, send us a message
                and we will get back to you soonest
              </p>
            </div>
          </div>

          <div className='mx-auto w-[88%] pb-20 '>
            <div className=' mt-10 lg:mx-52 md:mx-24 md:mb-12 mb-8 mx-auto space-y-6'>
              <Input
                placeholder='Your email'
                label='Email'
                className='md:mb-2 mb-4'
                onChange={(e) => setEmail(e.target.value)}
                value={email}
                type='email'
              />
              <TextArea
                placeholder='Drop a message'
                className=''
                label='Message'
                onChange={(e) => setMessage(e.target.value)}
                value={message}
              />
            </div>
            <div className='md:mb-2 py-4'>
              <Button
                disabled={!(email || message)}
                loading={loading}
                text={'Send message'}
                height='h-[50px]'
                className={
                  'bg-[#E60A2B] text-white-100 text-sm sm:text-lg w-[200px]  mx-auto   rounded-[40px]  '
                }
                onClick={() => submitQuestion()}
              />
            </div>
          </div>
          {showConfirm && (
            <div
              className={` transition-all flex justify-center ${
                showConfirm
                  ? 'opacity-100 md:translate-x-0'
                  : 'opacity-0 translate-x-100'
              } md:fixed md:top-20 md:right-10 z-[1000000]`}
            >
              <UserEmailReceived />
            </div>
          )}
        </div>
      </div>
      <div className='md:my-36'>
        <GetApp safari={safari} />
      </div>
      <Footer />
    </div>
  );
};

export default Contact;
