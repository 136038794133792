/** @format */

export const WingKingsBrandArray = [
  {
    meal: 'http://res.cloudinary.com/cokitchen/image/upload/v1629807939/optimized_nojy3n.jpg',
    description:
      'Crispy fried chicken with purple cabbage sauce, sour cream & hot sauce in a toasted bun. Served with choice of fries.',
    brandName: 'Smoked Chicken WingsSpicy Chicken Sandwich',
    restaurant: 'Wing Kings',
    link: 'https://link.getfoodcourt.com/brands/IlZfQrgQXZ8wHJ/vKW8dSBNP7e2Z31ab4dBOuZqPU7U3VkHVWrhLt56G7QNmR/AV/brand/KlNdEboXCZQwG56/dG8dH0QT7e3J1VSb4IAb6J2JVuVoBkHRWrhLt56G7QNmR/AV/menu/KgAFRe1CWp8wGJG9e28dSxNO7befj1CbvYNIvZuPA7QwU0PXWrhLt56G7QNmR/AV/share',
  },
  {
    meal: 'http://res.cloudinary.com/cokitchen/image/upload/v1665226938/xr6ofwpmm8vsjfm8stjo.jpg',
    description: 'Grilled chicken breast in a garden salad bed.',
    brandName: 'Grilled Chicken Salad',
    restaurant: 'Wing Kings',
    link: 'https://link.getfoodcourt.com/brands/IlZfQrgQXZ8wHJ/vKW8dSBNP7e2Z31ab4dBOuZqPU7U3VkHVWrhLt56G7QNmR/AV/brand/KlNdEboXCZQwG56/dG8dH0QT7e3J1VSb4IAb6J2JVuVoBkHRWrhLt56G7QNmR/AV/menu/L1YNE+4YWJ0wG8ruf28dHxJD7eyTilubsIJJ686OUOM0V0XZWrhLt56G7QNmR/AV/share',
  },
  {
    meal: 'http://res.cloudinary.com/cokitchen/image/upload/v1629808480/optimized_g0zxcw.jpg',
    description:
      'Peri-peri chicken, cheese & special sauce toastie. Served with choice of fries.',
    brandName: 'Peri Peri Chicken Toastie',
    restaurant: 'Wing Kings',
    link: 'https://link.getfoodcourt.com/brands/IlZfQrgQXZ8wHJ/vKW8dSBNP7e2Z31ab4dBOuZqPU7U3VkHVWrhLt56G7QNmR/AV/brand/KlNdEboXCZQwG56/dG8dH0QT7e3J1VSb4IAb6J2JVuVoBkHRWrhLt56G7QNmR/AV/menu/K1sFTb5FDpswTMiydW8dGhZE7ezJ3leb4YRLusrdU+UwAUfRWrhLt56G7QNmR/AV/share',
  },
  {
    meal: 'http://res.cloudinary.com/cokitchen/image/upload/v1629810803/optimized_vfqfov.jpg',
    description:
      'Chicken & mixed veg, drenched in special sauce & wrapped in toasted flat bread.',
    brandName: 'Chicken Shawarma',
    restaurant: 'Wing Kings',
    link: 'https://link.getfoodcourt.com/brands/IlZfQrgQXZ8wHJ/vKW8dSBNP7e2Z31ab4dBOuZqPU7U3VkHVWrhLt56G7QNmR/AV/brand/KlNdEboXCZQwG56/dG8dH0QT7e3J1VSb4IAb6J2JVuVoBkHRWrhLt56G7QNmR/AV/menu/fwVdRroYCc8wTczrLm8dTENH7bfL2lOb4IcbvcmNC+VlAEKFWrhLt56G7QNmR/AV/share',
  },
  {
    meal: 'http://res.cloudinary.com/cokitchen/image/upload/v1650275323/uotywq3avnrijsnpvh2t.jpg',
    description:
      'Classic chicken club sandwich with all the trimmings. Served with choice of sides.',
    brandName: 'Chicken Club Sandwich',
    restaurant: 'Wing Kings',
    link: 'https://link.getfoodcourt.com/brands/IlZfQrgQXZ8wHJ/vKW8dSBNP7e2Z31ab4dBOuZqPU7U3VkHVWrhLt56G7QNmR/AV/brand/KlNdEboXCZQwG56/dG8dH0QT7e3J1VSb4IAb6J2JVuVoBkHRWrhLt56G7QNmR/AV/menu/e1sPEL1HW58wTMu4eW8dSkhC7bfP3wCbsYdP7ZzcAudjBE3TWrhLt56G7QNmR/AV/share',
  },
  {
    meal: 'http://res.cloudinary.com/cokitchen/image/upload/v1629810157/optimized_azdgxj.jpg',
    description: 'Choice of 1 or 2 chicken flavours.',
    brandName: '14pc Chicken Box Platter',
    restaurant: 'Wing Kings',
    link: 'https://link.getfoodcourt.com/brands/IlZfQrgQXZ8wHJ/vKW8dSBNP7e2Z31ab4dBOuZqPU7U3VkHVWrhLt56G7QNmR/AV/brand/KlNdEboXCZQwG56/dG8dH0QT7e3J1VSb4IAb6J2JVuVoBkHRWrhLt56G7QNmR/AV/menu/KQYLRb5AWcgwS8q6fm8dSRMW7bfI2Vub4oNP75vZBe9jW03RWrhLt56G7QNmR/AV/share',
  },
];
