/** @format */

import React from 'react';
import TestimonialCarousel from './TestimonialCarousel';

const Testimonial = () => {
  return (
    <div className='bg-peach-100 py-20 xs:mb-28'>
      <div className=' space-y-20 text-grey-100  relative'>
        <p className='px-4 text-center text-grey-100 text-lg sm:text-2xl md:text-4xl font-noirbold'>
          {' '}
          Word on the street
        </p>

        <div className='relative md:py-20 h-[310px] md:h-[410px] '>
          <TestimonialCarousel />
        </div>
      </div>
    </div>
  );
};

export default Testimonial;
// <div className=" h-[100%] w-full ">
// 						<div className="absolute top-0  bg-white-100 h-[460px]  rounded-lg left-[50%] -translate-x-[50%]  w-[80%] md:w-[50%] mx-auto  z-8 drop-shadow-md   "></div>
// 						<div className="absolute top-0  bg-white-100 h-[440px] rounded-lg left-[50%] -translate-x-[50%]   w-[82%] md:w-[52%] mx-auto  z-10 drop-shadow-md "></div>
// 						<div className="absolute top-0  bg-white-100 h-[420px] rounded-lg left-[50%] -translate-x-[50%]  w-[84%] md:w-[54%] mx-auto  z-20 drop-shadow-md  "></div>
// 					</div>
