/** @format */

import React from "react";
import LocationLayout from "../../../components/locationlayout.js";
import Meta from "../../../components/meta/Meta";
import { VIArray } from "../../../constants/location/VIArray.js";
const CoKitchenVI = () => {
	<Meta
		title="Food delivery in Victoria Island, Lagos- Enjoy delicious food | getfoodcourt"
		description="Order food online from your favourite restaurants that deliver in Victoria Island, Lagos on getfoodcourt. Rice, jollof rice"
	/>;
	return (
		<LocationLayout
			locationName="Victoria Island"
			LocationBg={
				"https://res.cloudinary.com/olimeed/image/upload/q_auto:good/v1667275726/CoKiitche_Day_203043_qecjbn.jpg"
			}
			locationDesc={"Victoria Island is the main business and financial area of Lagos state in Nigeria. With a high concentration of top notch restaurants, cuisines and homes, it is one of the most exquisite areas in Lagos to live in. Living or working in Victoria Island is wonderful but going out to get your favorite meal may be a constraint and we don’t want you in such a fix. This is why we at FoodCourt, the best food delivery service in Victoria Island have set out to give you the best experience always. On our app, you can choose from a curation of the tastiest meals well prepared for your enjoyment and have them delivered to your doorstep in minutes! Get the FoodCourt app on the google play store or app store to open up to a world of premium enjoyment!"}
			brandArray={VIArray}
		/>
	);
};

export default CoKitchenVI;
