/** @format */

import React from "react";
import { Link } from "react-router-dom";
import { LANDING } from "../../routes";

const NoPageFound = () => {
	return (
		<div className="grid place-content-center font-noirbold h-[100vh]">
			<div className="flex flex-col justify-center items-center">
				404 - No Page Found
				<Link to={LANDING} className="underline font-noirbold">
					GO HOME
				</Link>
			</div>
		</div>
	);
};

export default NoPageFound;
