/** @format */

import React from "react";

const Burger = ({ openNav, toggleNav, isStickyNav, isWhite }) => {
	return (
		<div
			className="flex md:hidden flex-col space-y-1 cursor-pointer"
			onClick={toggleNav}
		>
			<span
				className={`${openNav
						? "z-[300000000000000] bg-black-200 rotate-[45deg] "
						: "rotate-0 bg-white-100"
					} 	${(isStickyNav || isWhite) && "bg-black-200"}
 w-[22px] xs:w-[30px] h-[2px] rounded-sm origin-[13%] xs:origin-[23%] transition-prop  `}
			></span>
			<span
				className={`${!openNav
						? "translate-x-0 opacity-100 bg-white-100"
						: "z-[3000]  translate-x-[-100vw] opacity-0  bg-black-100"
					} 	${(isStickyNav || isWhite) && "bg-black-200"}
 w-[22px] xs:w-[30px] h-[2px] rounded-sm origin-[13%] xs:origin-[23%] transition-prop`}
			></span>
			<span
				className={`${openNav
						? "z-[3000] bg-black-200 rotate-[-45deg]"
						: "rotate-0 bg-white-100"
					} 	${(isStickyNav || isWhite) && "bg-black-200"}
 w-[22px] xs:w-[30px] h-[2px] rounded-sm origin-[13%] xs:origin-[23%] transition-prop`}
			></span>
		</div>
	);
};

export default Burger;
