/** @format */

import React from 'react';

const FeaturesCard = ({ featureImg, title, details, delay }) => {
  return (
    <div className='flex flex-col space-y-3 w-[90%] sm:w-[230px]  text-grey-100 items-start mx-auto sm:mx-0 '>
      <div className='overflow-hidden rounded-lg '>
        <img
          src={featureImg}
          alt={details}
          className='w-full rounded-lg transition-prop'
        />
      </div>

      <h5 className='ttext-xl sm:text-2xl font-noirbold'>{title}</h5>
      <p className='text-base'>{details}</p>
    </div>
  );
};

export default FeaturesCard;
