import React from "react";
import MenuPdf from "../../assets/pdfs/menu.pdf";
const Menu = () => {
	return (
		<div className="h-screen">
			<iframe title='LoveandLuxe' className="min-w-full min-h-full" height="100%" src={MenuPdf} />
		</div>
	);
};

export default Menu;
