/** @format */
import React from 'react';
import Footer from '../footer';
import Meta from '../meta/Meta';
import LocationLayoutContent from './LocationLayoutContent';
import LocationLayoutFooter from './LocationLayoutFooter';
import LocationLayoutHeader from './LocationLayoutHeader';

const LocationLayout = ({
  locationName,
  LocationBg,
  brandArray,
  locationDesc,
}) => {
  return (
    <div>
      <Meta
        url={`https://www.getfoodcourt.com/locations/${locationName}`}
        title={`Order food online in ${locationName}, Lagos - Enjoy delicious food | getfoodcourt`}
        description={`Order food online in ${locationName} from your favorite restaurant that deliver on the island - Everyday naija Jollof,Fried Rice, Good chicken & more, Delicious & authentic Asian flavours, Breakfast to start your day right, Mamas special local dishes, Gourmet burgers. hotdogs & more!, Delicious shawarma, wraps & more!, Snacks, liquor, essentials & more! Food In ${locationName}`}
      />
      <LocationLayoutHeader
        locationName={locationName}
        LocationBg={LocationBg}
        locationDesc={locationDesc}
      />
      <LocationLayoutContent
        brandArray={brandArray}
        locationName={locationName}
      />

      <LocationLayoutFooter />

      <Footer />
    </div>
  );
};

export default LocationLayout;
