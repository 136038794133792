/** @format */

import React from "react";
import Phone from "../../assets/img/FC_phone.webp";
import DownloadButtons from "../../components/download-buttons";

const GetApp = ({ safari }) => {
	return (
		<div className="mx-auto relative w-full xl:px-16">
			<div className="bg-gradient w-full xs:w-[90%] md:w-[70%] xs:rounded-3xl mx-auto ">
				<div className="px-6 sm:px-20 flex flex-col justify-center mdLg:justify-between mdLg:flex-row sm:gap-12">
					<div className="flex flex-col py-10 text-left justify-center mdLg:justify-start mx-auto mdLg:mx-0 space-y-6">
						<h3 className="text-2xl md:text-3xl lg:text-[45px] mdLg:leading-[55px] font-noirbold text-white-100 ">
							Limitless convenience <br />
							on-demand.
						</h3>
						<p className=" text-black-100 text-[60px] sm:leading-[50px] md:text-[75px] md:leading-[100px] font-holimount">
							Get the app!
						</p>
						<DownloadButtons safari={safari} />
					</div>

					<div className="w-[250px] xs:w-[300px] right-[3rem] lg:right-[10rem] top-[-4rem] mdLg:w-[300px] mdLg:absolute transition-prop mx-auto mdLg:mx-0">
						<img src={Phone} alt="Download App" className="w-[100%] " />
					</div>
				</div>
			</div>
		</div>
	);
};

export default GetApp;
