/** @format */

export const WrapCityBrandArray = [
  {
    meal: 'http://res.cloudinary.com/cokitchen/image/upload/q_auto:good/v1659916725/edoe5tplf1qorz7ij0xf.jpg',
    description:
      'Special shawarma loaded with pulled BBQ chicken with veggies & special sauce.',
    brandName: 'Pulled BBQ Chicken Shawarma',
    restaurant: 'Wrap City',
    link: 'https://link.getfoodcourt.com/brands/IlZfQrgQXZ8wHJ/vKW8dSBNP7e2Z31ab4dBOuZqPU7U3VkHVWrhLt56G7QNmR/AV/brand/KlEIFL4VDs8wSJqzdG8dTElB7beSjVqbs9MauceIUeFjAUeEWrhLt56G7QNmR/AV/menu/eVBfQORHCM8wG5q6L28dRUMW7ezI3FGbso5Ovp7ZB7ZpVETUWrhLt56G7QNmR/AV/share',
  },
  {
    meal: 'https://res.cloudinary.com/cokitchen/image/upload/q_auto:good/v1659916742/gmvwkby2i7nruwoyz3h5.jpg',
    description:
      'The great meat lovers shawarma loaded with beef brisket, smoked beef, chopped sausage & pepperoni.',
    brandName: 'Meat Lovers Shawarma',
    restaurant: 'Wrap City',
    link: 'https://link.getfoodcourt.com/brands/IlZfQrgQXZ8wHJ/vKW8dSBNP7e2Z31ab4dBOuZqPU7U3VkHVWrhLt56G7QNmR/AV/brand/KlEIFL4VDs8wSJqzdG8dTElB7beSjVqbs9MauceIUeFjAUeEWrhLt56G7QNmR/AV/menu/KFsIQOVEDp0wH5juLm8dGhJE7eyZ3AabtdRI6JndBeQ3UxbRWrhLt56G7QNmR/AV/share',
  },
  {
    meal: 'https://res.cloudinary.com/cokitchen/image/upload/q_auto:good/v1659916760/ifpn9e9iczuypnyrhlgy.jpg',
    description:
      'Healthy wrap with mixed seasonal vegtables, fresh herbs & special sauce.',
    brandName: 'Veggie Supreme Wrap',
    restaurant: 'Wrap City',
    link: 'https://link.getfoodcourt.com/brands/IlZfQrgQXZ8wHJ/vKW8dSBNP7e2Z31ab4dBOuZqPU7U3VkHVWrhLt56G7QNmR/AV/brand/KlEIFL4VDs8wSJqzdG8dTElB7beSjVqbs9MauceIUeFjAUeEWrhLt56G7QNmR/AV/menu/fFQLRLgQCJQwSMy5f28dHRYS7eyT1Febs9BJ783YAOdgBxXTWrhLt56G7QNmR/AV/share',
  },
  {
    meal: 'http://res.cloudinary.com/cokitchen/image/upload/q_auto:good/v1659916872/dokkv5lboo306qs8fbhw.jpg',
    description:
      'French fries topped with chopped suya chicken, veg, chili mayo & sauces.',
    brandName: 'Suya Chicken Fries',
    restaurant: 'Wrap City',
    link: 'https://link.getfoodcourt.com/brands/IlZfQrgQXZ8wHJ/vKW8dSBNP7e2Z31ab4dBOuZqPU7U3VkHVWrhLt56G7QNmR/AV/brand/KlEIFL4VDs8wSJqzdG8dTElB7beSjVqbs9MauceIUeFjAUeEWrhLt56G7QNmR/AV/menu/LVQKEe8YDJ8wGZy/KW8dSxYW7e2S31SbtIcauMmPUeJhB0XZWrhLt56G7QNmR/AV/share',
  },
  {
    meal: 'http://res.cloudinary.com/cokitchen/image/upload/q_auto:good/v1659916609/jilpgq7otbpo0cfvkecl.jpg',
    description:
      'French fries topped with minced meat, chopped sausages, veg, cheese & sauces. e',
    brandName: 'Beef Chili Cheese Fries',
    restaurant: 'Wrap City',
    link: 'https://link.getfoodcourt.com/brands/IlZfQrgQXZ8wHJ/vKW8dSBNP7e2Z31ab4dBOuZqPU7U3VkHVWrhLt56G7QNmR/AV/brand/KlEIFL4VDs8wSJqzdG8dTElB7beSjVqbs9MauceIUeFjAUeEWrhLt56G7QNmR/AV/menu/fAUOQe1DD5swT8q4K28dSkFG7e3P31Cb4YRMuJvZAOE0AUeFWrhLt56G7QNmR/AV/share',
  },
  {
    meal: 'http://res.cloudinary.com/cokitchen/image/upload/q_auto:good/v1659916807/ucq3dd7ocxja07pk6ybn.jpg',
    description:
      'Classic mixed beef & chicken shawarma with veg & special sauce.',
    brandName: 'Classic Mixed Shawarma',
    restaurant: 'Wrap City',
    link: 'https://link.getfoodcourt.com/brands/IlZfQrgQXZ8wHJ/vKW8dSBNP7e2Z31ab4dBOuZqPU7U3VkHVWrhLt56G7QNmR/AV/brand/KlEIFL4VDs8wSJqzdG8dTElB7beSjVqbs9MauceIUeFjAUeEWrhLt56G7QNmR/AV/menu/fAIIQegWWJowEZ+7fG8dRBUU7ezP3VGbsNAd656OBLY3WxGDWrhLt56G7QNmR/AV/share',
  },
];
