/** @format */

import React from "react";
import BrandLayout from "../../components/brandlayout/BrandLayout";
import { RedPepperBrandArray } from "../../constants/RedPepperBrandArray";
const RedPepper = () => {
  return (
    <BrandLayout
      img={
        "https://d3u7b9fq2opvwp.cloudfront.net/upload-service/a8712d88-1a92-4f6f-a316-f3f93c7d7019:FC%20Red%20Pepper18170.jpg"
      }
      brandName={"Red Pepper"}
      igLink=""
      brandLongDesc={
        "A colorful blend of Naija staples from rice meals to mouthwatering grilled eats. Red Pepper invites you on a journey where every bite is a burst of vibrant flavors."
      }
      brandArray={RedPepperBrandArray}
    />
  );
};

export default RedPepper;
