/** @format */

import React from "react";
import BrandLayout from "../../components/brandlayout/BrandLayout";
import { LagosBreakfastClubBrandArray } from "../../constants/LagosBreakfastClubBrandArray";
const LagosBreakfastClub = () => {
  return (
    <BrandLayout
      img={
        "http://res.cloudinary.com/cokitchen/image/upload/v1687121133/en33r0svvyixf45jjufk.jpg"
      }
      brandName={"Lagos Breakfast Club"}
      igLink="https://www.instagram.com/lagosbreakfastclub"
      brandLongDesc={
        "Indulge in Authentic Nigerian breakfast staples from crunchy Akara balls to creamy bowls of oatmeal and so much more."
      }
      brandArray={LagosBreakfastClubBrandArray}
    />
  );
};

export default LagosBreakfastClub;
