import React from "react";
import Helmet from "react-helmet";

const Meta = ({
	url = "https://www.getfoodcourt.com/",
	title = "Food delivery service in Lekki | getfoodcourt ",
	description = "Let’s satisfy your cravings, order our delicious meals in Lekki, Ikoyi and VI and get it in minutes.",
}) => {
	return (
		<Helmet title={title}>
			<link href="" rel="canonical" />
			<meta name="robots" content="index"/>
			<meta name="charset" content="utf-8"/>
			<meta name="theme-color" content="#F2F2F2" />
			<meta name="apple-mobile-web-app-status-bar-style" content="#F2F2F2" />
			<meta name="description" content={description} />
			<meta name="google-site-verification" content="+nxGUDJ4QpAZ5l9Bsjdi102tLVC21AIh5d1Nl23908vVuFHs34=" />
			<meta
				property="twitter:card"
				content="summary_large_image"
				vmid="twitter:card"
			/>
			<meta property="og:type" content="website" />
			<meta
				property="og:title"
				content="FoodCourt - Order Good Food, Snacks, Essentials & More In Ibeju Lekki, Lagos Island, Eti Osa, Victoria Island And Get It Delivered In Minutes!"
			/>
			<meta property="og:url" content={url} />
			<meta
				property="og:image"
				content="https://res.cloudinary.com/olimeed/image/upload/v1654087776/logo_zlmtbs.png"
			/>
			<meta
				property="og:description"
				content="#1 Food & Grocery Delivery App. Order Everything you need in 1 basket - good food, snacks, liquor & essentials all on the FoodCourt app."
			/>
			<meta property="og:site_name" content={title} />
			<meta property="og:locale" content="en_US" />
			<meta
				name="twitter:creator"
				content="https://www.twitter.com/foodcourt_app"
			/>

			<meta data-hid="twitter:title" name="twitter:title" content={title} />
			<meta
				data-hid="twitter:description"
				name="twitter:description"
				content={title}
			/>
			<meta
				data-hid="twitter:image"
				name="twitter:image"
				content="https://res.cloudinary.com/olimeed/image/upload/v1654087776/logo_zlmtbs.png"
			/>
			<meta
				data-hid="twitter:image:alt"
				name="twitter:image:alt"
				content="Foodcourt SEO Image"
			/>
			<meta
				data-hid="og:image:secure_url"
				property="og:image:secure_url"
				content="https://res.cloudinary.com/olimeed/image/upload/v1654087776/logo_zlmtbs.png"
			/>
			<meta
				data-hid="og:image:alt"
				property="og:image:alt"
				content="Foodcourt SEO Image"
			/>

			<meta
				name="twitter:description"
				content="#1 Delivery App. Everything you need in 1 basket - good food, snacks, liquor & essentials all on the FoodCourt app. Food In Lekki, Food On The Island, Food In Victoria Island, Food In VI"
			/>
		    <meta name="google-site-verification" content="+nxGUDJ4QpAZ5l9Bsjdi102tLVC21AIh5d1Nl23908vVuFHs34=" />
			<meta name="twitter:image" content="" />
			<meta
				name="twitter:url"
				content="https://www.twitter.com/foodcourt_app"
			/>
		</Helmet>
	);
};

export default Meta;

// #1 Delivery App. Everything you need in 1 basket - good food, snacks, liquor & essentials all on the FoodCourt app. Food In Lekki, Food On The Island, Food In Victoria Island, Food In VI
