/** @format */
import PC1 from '../assets/img/FC Pastries12334.jpg';
import PC2 from '../assets/img/FC Pastries12362.jpg';
import PC3 from '../assets/img/FC Pastries12164.jpg';
import PC4 from '../assets/img/FC Pastries12375.jpg';
import PC5 from '../assets/img/FC Pastries12151.jpg';
export const PastryCornerBrandArray = [
  {
    meal: PC1,
    description:
      'Create your box with your favorite  filled doughnuts flavors ',
    brandName: 'Special Filled Doughnut Box',
    restaurant: 'Pastry Corner',
    link: 'https://link.getfoodcourt.com/brands/IlZfQrgQXZ8wHJ/vKW8dSBNP7e2Z31ab4dBOuZqPU7U3VkHVWrhLt56G7QNmR/AV/brand/fFNYR+sWWpowSpi/em8dSUYW7bSe2Vubt4NJ6MjZBeNgVEDQWrhLt56G7QNmR/AV/menu/LgVdEbgTW88wH5C8KW8dRUlA7beaiFSb5dAc7ceNUeIyVhKCWrhLt56G7QNmR/AV/share',
  },
  {
    meal: 'https://d3u7b9fq2opvwp.cloudfront.net/upload-service/ebbb5c0e-a0ca-42d6-9b70-5d4076126dfd:FC%20Pastries12153.jpg',
    description: 'Soft And Fluffy Donut Coated In Granulated Sugar',
    brandName: 'Fluffy Sugar Donut',
    restaurant: 'Pastry Corner',
    link: 'https://link.getfoodcourt.com/brands/IlZfQrgQXZ8wHJ/vKW8dSBNP7e2Z31ab4dBOuZqPU7U3VkHVWrhLt56G7QNmR/AV/brand/fFNYR+sWWpowSpi/em8dSUYW7bSe2Vubt4NJ6MjZBeNgVEDQWrhLt56G7QNmR/AV/menu/LldYE+0YC5kwTM+6fW8dH0NO7bSb21Ob4o8dvZnYUOdhAUHQWrhLt56G7QNmR/AV/share',
  },
  {
    meal: PC2,
    description: 'Fudgy Chocolatey Melt-In- The Mouth Brownie',
    brandName: 'Chocolate Fudge',
    restaurant: 'Pastry Corner',
    link: 'https://link.getfoodcourt.com/brands/IlZfQrgQXZ8wHJ/vKW8dSBNP7e2Z31ab4dBOuZqPU7U3VkHVWrhLt56G7QNmR/AV/brand/fFNYR+sWWpowSpi/em8dSUYW7bSe2Vubt4NJ6MjZBeNgVEDQWrhLt56G7QNmR/AV/menu/eVQLQuwSX5swSJm/eG8dTEMV7e2d2QCb5dUa7c2OBOYyVxbUWrhLt56G7QNmR/AV/share',
  },
  {
    meal: PC3,
    description: 'Soft Dough Swirled With Swert Blend Of Cinnamon And Butter',
    brandName: 'Cinnamon Swirled Pastry',
    restaurant: 'Pastry Corner',
    link: 'https://link.getfoodcourt.com/brands/IlZfQrgQXZ8wHJ/vKW8dSBNP7e2Z31ab4dBOuZqPU7U3VkHVWrhLt56G7QNmR/AV/brand/fFNYR+sWWpowSpi/em8dSUYW7bSe2Vubt4NJ6MjZBeNgVEDQWrhLt56G7QNmR/AV/menu/LFUJE+sZUp4wEc+7dW8dREhB7bebj1qb4NUausfbV+UwAUHWWrhLt56G7QNmR/AV/share',
  },
  {
    meal: PC4,
    description: 'Flaky Buttery Jamaican Style Beef Patty',
    brandName: 'Jamaican Style Beef Patty',
    restaurant: 'Pastry Corner',
    link: 'https://link.getfoodcourt.com/brands/IlZfQrgQXZ8wHJ/vKW8dSBNP7e2Z31ab4dBOuZqPU7U3VkHVWrhLt56G7QNmR/AV/brand/fFNYR+sWWpowSpi/em8dSUYW7bSe2Vubt4NJ6MjZBeNgVEDQWrhLt56G7QNmR/AV/menu/flULQb9EW5UwSpC8e28dTkdD7e2b31abtYYfvc6PAO9nUBHTWrhLt56G7QNmR/AV/share',
  },
  {
    meal: PC5,
    description: 'Soft and Fluffy Donut',
    brandName: ' Plain Fluffy Doughnut',
    restaurant: 'Pastry Corner',
    link: 'https://link.getfoodcourt.com/brands/IlZfQrgQXZ8wHJ/vKW8dSBNP7e2Z31ab4dBOuZqPU7U3VkHVWrhLt56G7QNmR/AV/brand/fFNYR+sWWpowSpi/em8dSUYW7bSe2Vubt4NJ6MjZBeNgVEDQWrhLt56G7QNmR/AV/menu/flYETOQSUpwwSMyyeW8dSEJF7e2b21ubvIEfvZzaCuJhA0TVWrhLt56G7QNmR/AV/share',
  },
];
