import React from 'react'
import Footer from '../../components/footer'
import Header from '../../components/header'
import BgImage from '../../assets/img/email-verification-bg.png'
import { useState } from 'react'
import EmailStatus from './email-status'
import { useParams } from 'react-router-dom'
import DataService from '../../services/support'
import { useEffect } from 'react'
const EmailVerification = () => {
  const [status, setStatus] = useState(0)
  const { token } = useParams()
  const emailVerification = async () => {
    let payload = {
      token
    };
    try {
      let res = await DataService.postInternalData(
        "user/confirm-email",
        payload,
        token
      );
      if (res) setStatus(1)
    } catch (err) {
      if (err)
        setStatus(2)
    }
  };

  useEffect(() => {
    emailVerification()
  }, [])

  return (
    <div className='bg-[#F7F7F7]'>
      <Header isEmail />
      <div className="py-28 sm:mx-8">
        <div className='bg-white-100 px-3 sm:px-6 rounded-[40px] h-[80vh] relative'>
          <img src={BgImage} alt="" className='h-auto w-[50%] absolute bottom-0 left-[50%] -translate-x-[50%]' />
          <EmailStatus status={status} />
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default EmailVerification
