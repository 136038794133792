/** @format */

import React from 'react';
import BrandLayout from '../../components/brandlayout/BrandLayout';
import { SunnySideClubBrandArray } from '../../constants/SunnySideClubBrandArray';

const SunnySideClub = () => {
  return (
    <BrandLayout
      img={
        'https://res.cloudinary.com/olimeed/image/upload/q_auto:good/v1666863214/Sunny_side_ziu9xu.png'
      }
      brandName={'Sunny Side Club '}
      igLink='https://www.instagram.com/the.sunnysideclub/'
      brandLongDesc={
        'Treat yourself each day to a morning of bliss with any of our simply delectable toast, waffles, pancakes, sandwiches, and more! Exclusively on FoodCourt'
      }
      brandArray={SunnySideClubBrandArray}
    />
  );
};

export default SunnySideClub;
