/** @format */

export const JollofCoBrandArray = [
  {
    meal: 'http://res.cloudinary.com/cokitchen/image/upload/q_auto:good/v1629810276/optimized_uz2czw.jpg',
    description:
      'Delicious party style smokey jollof rice with choice of protein & sides.',
    brandName: 'Smokey Jollof Rice',
    restaurant: 'Jollof & Co.',
    link: 'https://link.getfoodcourt.com/brands/IlZfQrgQXZ8wHJ/vKW8dSBNP7e2Z31ab4dBOuZqPU7U3VkHVWrhLt56G7QNmR/AV/brand/eAUKRb9EWpowSp3rLG8dHUVP7ezI1FCbvNRM6ZuJB+doB0fYWrhLt56G7QNmR/AV/menu/I1cPTOpHWZgwTMiydG8dS0RG7e2d2AabsdVJ7s2KUe40BkbQWrhLt56G7QNmR/AV/share',
  },
  {
    meal: 'http://res.cloudinary.com/cokitchen/image/upload/q_auto:good/v1664731689/grf0uwbvcrlsfxmqszi1.jpg',
    description:
      'Fried rice with bits of chicken, goat meat, fish & mixed veg. ',
    restaurant: 'Jollof & Co.',
    brandName: 'Naija Special Fried Rice ',
    link: 'https://link.getfoodcourt.com/brands/IlZfQrgQXZ8wHJ/vKW8dSBNP7e2Z31ab4dBOuZqPU7U3VkHVWrhLt56G7QNmR/AV/brand/eAUKRb9EWpowSp3rLG8dHUVP7ezI1FCbvNRM6ZuJB+doB0fYWrhLt56G7QNmR/AV/menu/I1QIEOwWC54wT5Dufm8dGhNO7e3IjlObtYdL7cyJUbUyVhfRWrhLt56G7QNmR/AV/share',
  },
  {
    meal: 'http://res.cloudinary.com/cokitchen/image/upload/q_auto:good/v1650275264/tpn6mlsjpi6qqtp5cptu.jpg',
    description:
      'Stir-fry spaghetti with chicken strips, chopped peppers & carrots.',
    restaurant: 'Jollof & Co.',
    brandName: 'Chicken Stir-fry Spaghetti',
    link: 'https://link.getfoodcourt.com/brands/IlZfQrgQXZ8wHJ/vKW8dSBNP7e2Z31ab4dBOuZqPU7U3VkHVWrhLt56G7QNmR/AV/brand/eAUKRb9EWpowSp3rLG8dHUVP7ezI1FCbvNRM6ZuJB+doB0fYWrhLt56G7QNmR/AV/menu/IlAEEboVXJ8wH5m7LG8dGkVD7bed2FSbt4VPvZ3bUOdiUECAWrhLt56G7QNmR/AV/share',
  },
  {
    meal: 'http://res.cloudinary.com/cokitchen/image/upload/q_auto:good/v1629809498/optimized_jqpl8u.jpg',
    description: 'Spicy tomato-based minced meat twist pasta',
    restaurant: 'Jollof & Co.',
    brandName: 'Minced Meat Pasta',
    link: 'https://link.getfoodcourt.com/brands/IlZfQrgQXZ8wHJ/vKW8dSBNP7e2Z31ab4dBOuZqPU7U3VkHVWrhLt56G7QNmR/AV/brand/eAUKRb9EWpowSp3rLG8dHUVP7ezI1FCbvNRM6ZuJB+doB0fYWrhLt56G7QNmR/AV/menu/KwALR+xDDpswHpmyK28dSkZC7eyf3wWb4NNP6ZrdBbZgBE2CWrhLt56G7QNmR/AV/share',
  },
  {
    meal: 'http://res.cloudinary.com/cokitchen/image/upload/q_auto:good/v1664731622/zwjmhivzsxrpfsskgwbl.jpg',
    description: 'Fully loaded white rice, spaghetti, beans & plantain combo.',
    restaurant: 'Jollof & Co.',
    brandName: 'Full Rambo Special ',
    link: 'https://link.getfoodcourt.com/brands/IlZfQrgQXZ8wHJ/vKW8dSBNP7e2Z31ab4dBOuZqPU7U3VkHVWrhLt56G7QNmR/AV/brand/eAUKRb9EWpowSp3rLG8dHUVP7ezI1FCbvNRM6ZuJB+doB0fYWrhLt56G7QNmR/AV/menu/KABdFu4YX80wG8zpKG8dHUhC7eyaiVubtYQc6ZmIBec1A0yFWrhLt56G7QNmR/AV/share',
  },
  {
    meal: 'http://res.cloudinary.com/cokitchen/image/upload/q_auto:good/v1664731665/vtzbgm8gtoukfvu6f8fg.jpg',
    description: 'Fish sauce and boiled potatoes topped with chilli flakes. ',
    restaurant: 'Jollof & Co.',
    brandName: 'Spicy Potatoes & Fish Sauce ',
    link: 'https://link.getfoodcourt.com/brands/IlZfQrgQXZ8wHJ/vKW8dSBNP7e2Z31ab4dBOuZqPU7U3VkHVWrhLt56G7QNmR/AV/brand/eAUKRb9EWpowSp3rLG8dHUVP7ezI1FCbvNRM6ZuJB+doB0fYWrhLt56G7QNmR/AV/menu/K1RZQOUQD8owSMrsfm8dGhIU7eyS2VCbtoUd756JBuJiBhLZWrhLt56G7QNmR/AV/share',
  },
];
