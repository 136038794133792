/** @format */

import React from 'react';
import BrandLayout from '../../components/brandlayout/BrandLayout';
import { FrankiesBrandArray } from '../../constants/FrankiesBrandArray';

const Frankies = () => {
  return (
    <BrandLayout
      img={
        'https://res.cloudinary.com/olimeed/image/upload/q_auto:good/v1666863217/Frankies_ohz11x.png'
      }
      brandName={'Frankies'}
      igLink='https://www.instagram.com/eat_frankies/'
      brandLongDesc={
        'You’ll keep coming back for more after a first trial of the juiciest Burgers & Hotdogs in Lagos! You’ll never go wrong with this mouth-watering heavenly goodness! Exclusively on FoodCourt.'
      }
      brandArray={FrankiesBrandArray}
    />
  );
};

export default Frankies;
