/** @format */

import React, { useEffect } from 'react';
import Footer from '../footer';
import Header from '../header';
import BrandLayoutContent from './BrandLayoutContent';
import BrandLayoutHeader from './BrandLayoutHeader';
import Meta from '../meta/Meta';

const BrandLayout = ({
  safari,
  img,
  brandName,
  igLink,
  brandArray,
  noIg,
  brandLongDesc,
}) => {
  useEffect(() => {
    window.rudderanalytics.track('Restaurant Page Viewed', {
      restaurant: brandName,
    });
  }, [brandName]);

  return (
    <div>
      <Meta
        title={`${brandName} || FoodCourt `}
        url='https://www.getfoodcourt.com/brands'
        description={`Order food online from ${brandName} - Need help placing an order? Please call: +234 1 888 0265 | From 8am to 12am | 7 days a week `}
      />
      <Header isWhite />
      <BrandLayoutHeader
        safari={safari}
        img={img}
        brandName={brandName}
        brandLongDesc={brandLongDesc}
      />
      <BrandLayoutContent
        brandName={brandName}
        igLink={igLink}
        brandArray={brandArray}
        noIg={noIg}
      />

      <Footer />
    </div>
  );
};

export default BrandLayout;
