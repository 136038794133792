/** @format */

import React from 'react';
import { ReactComponent as LeftArrow } from '../../assets/img/arrow-circle-left.svg';
import { ReactComponent as RightArrow } from '../../assets/img/arrow-circle-right.svg';
// Import css files
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { useState } from 'react';
import { useEffect } from 'react';
import DesktopCarousel from './DesktopCarousel';
import MobileCarousel from './MobileCarousel';

const BrandImages = () => {
  const [isMobile, setIsMobile] = useState(false);

  const setScreenSize = () => {
    if (window.innerWidth > 800) {
      setIsMobile(false);
    } else {
      setIsMobile(true);
    }
  };

  useEffect(() => {
    setScreenSize();
  }, [window.innerWidth]);

  var settings = {
    dots: false,
    infinite: true,
    autoplay: true,
    arrows: true,
    autoplaySpeed: 3000,
    speed: 600,
    slidesToShow: 3,
    // className: "slick-dots",
    // fade: true,
    lazyLoad: 'ondemand',
    swipe: true,
    nextArrow: <RightArrow />,
    prevArrow: <LeftArrow />,

    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
      {isMobile ? <MobileCarousel /> : <DesktopCarousel settings={settings} />}
    </>
  );
};

export default BrandImages;
