/** @format */
import RP1 from '../assets/img/FC Red Pepper17912.jpg';
import RP2 from '../assets/img/FC Red Pepper17894.jpg';
import RP3 from '../assets/img/FC Red Pepper17816.jpg';
import RP4 from '../assets/img/FC Red Pepper17802.jpg';
import RP5 from '../assets/img/FC Red Pepper17863.jpg';
import RP6 from '../assets/img/FC Red Pepper17987.jpg';

export const RedPepperBrandArray = [
  {
    meal: RP1,
    description:
      'smokey jollof rice, classic fried rice, grilled chicken and coleslaw',
    brandName: 'Owambe Party Mix ',
    restaurant: 'Red Pepper',
    link: 'https://link.getfoodcourt.com/brands/IlZfQrgQXZ8wHJ/vKW8dSBNP7e2Z31ab4dBOuZqPU7U3VkHVWrhLt56G7QNmR/AV/brand/KAEFTb1FXJUwHMjseW8dGUhH7ezL1QWb54Ic7ZzUUOUzBBbSWrhLt56G7QNmR/AV/menu/IgIJTe4VC84wT8rvfG8dSEVG7e2T2lqb4oZM6MraBuU0AETRWrhLt56G7QNmR/AV/share',
  },
  {
    meal: RP2,
    description:
      'White Rice and Bukka Stew that comes with kpomo, beef and boiled egg',
    brandName: 'Iya Basira Rice Bowl ',
    restaurant: 'Red Pepper',
    link: 'https://link.getfoodcourt.com/brands/IlZfQrgQXZ8wHJ/vKW8dSBNP7e2Z31ab4dBOuZqPU7U3VkHVWrhLt56G7QNmR/AV/brand/KAEFTb1FXJUwHMjseW8dGUhH7ezL1QWb54Ic7ZzUUOUzBBbSWrhLt56G7QNmR/AV/menu/K1UKF+VAUpwwHp/sf28dSEJF7e3O3QKb4I8bus/dAeU1BhLXWrhLt56G7QNmR/AV/share',
  },
  {
    meal: RP3,
    description:
      'Rice with Ofada red pepper sauce, served with assorted meat, plantain and egg',
    brandName: 'Red Pepper Ofada Bowl',
    restaurant: 'Red Pepper',
    link: 'https://link.getfoodcourt.com/brands/IlZfQrgQXZ8wHJ/vKW8dSBNP7e2Z31ab4dBOuZqPU7U3VkHVWrhLt56G7QNmR/AV/brand/KAEFTb1FXJUwHMjseW8dGUhH7ezL1QWb54Ic7ZzUUOUzBBbSWrhLt56G7QNmR/AV/menu/KlIPTOVFDJswHZDrKW8dS0FG7bSd2webtYAb6JyNAuVlB0PVWrhLt56G7QNmR/AV/share',
  },
  {
    meal: RP4,
    description:
      'Jollof basmati rice with suya flavour that comes with your choice of beef or chicken suya',
    brandName: 'Yaji Spicy Rice bowl ',
    restaurant: 'Red Pepper',
    link: 'https://link.getfoodcourt.com/brands/IlZfQrgQXZ8wHJ/vKW8dSBNP7e2Z31ab4dBOuZqPU7U3VkHVWrhLt56G7QNmR/AV/brand/KAEFTb1FXJUwHMjseW8dGUhH7ezL1QWb54Ic7ZzUUOUzBBbSWrhLt56G7QNmR/AV/menu/KFoNQO0VD50wS56ydW8dTxFD7eySigWb5oMb5M2KVOc3BEyDWrhLt56G7QNmR/AV/share',
  },
  {
    meal: RP5,
    description:
      'Fried rice steamed with smoked asun and vegetables, served with grilled chicken and plantain',
    brandName: 'Asun Rice Bowl',
    restaurant: 'Red Pepper',
    link: 'https://link.getfoodcourt.com/brands/IlZfQrgQXZ8wHJ/vKW8dSBNP7e2Z31ab4dBOuZqPU7U3VkHVWrhLt56G7QNmR/AV/brand/KAEFTb1FXJUwHMjseW8dGUhH7ezL1QWb54Ic7ZzUUOUzBBbSWrhLt56G7QNmR/AV/menu/fAYOR+0ZWc8wHpq9KG8dTRYT7bTL3Vebs9Qd6s2IV+Y3BEHXWrhLt56G7QNmR/AV/share',
  },
  {
    meal: RP6,
    description:
      'White rice paired with plain beans with our red pepper stew and protein of your choice',
    brandName: 'Rice and Beans Special Bowl ',
    restaurant: 'Red Pepper',
    link: 'https://link.getfoodcourt.com/brands/IlZfQrgQXZ8wHJ/vKW8dSBNP7e2Z31ab4dBOuZqPU7U3VkHVWrhLt56G7QNmR/AV/brand/KAEFTb1FXJUwHMjseW8dGUhH7ezL1QWb54Ic7ZzUUOUzBBbSWrhLt56G7QNmR/AV/menu/KQYPTb9HCMowGJjpf28dGklD7e2Y3lCbtoVLvp3dBuZjAUfZWrhLt56G7QNmR/AV/share',
  },
];
