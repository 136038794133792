/** @format */

import React from "react";
import LocationLayout from "../../../components/locationlayout.js";
import Meta from "../../../components/meta/Meta";
import { MarinaArray } from "../../../constants/location/MarinaArray";

const CoKitchenMarina = () => {
	<Meta
		title="Food delivery in Marina, Lagos- Enjoy delicious food | getfoodcourt"
		description="Order food online from your favourite restaurants that deliver in Marina, Lagos on getfoodcourt. Rice, jollof rice"
	/>;
	return (
		<LocationLayout
			locationName="Marina"
			LocationBg={
				"https://res.cloudinary.com/olimeed/image/upload/v1667276175/CoKiitche-Day-203263_wwdtof.jpg"
			}
			locationDesc={"Marina is a commercial center with high standard restaurants in Lagos. It is home to busy professionals, expatriates and of course Nigerians. If you live or work in this area, we are certain you will prefer to place orders from the best restaurants near you and have your food delivered to your doorstep. We at FoodCourt, the best food delivery in Marina have sought out to make sure that you enjoy all the comfort you require while you enjoy absolutely delicious meals! With our app, you can get food fromthe convenience of your sofa. Relax, order from the best food brands and enjoy a timely home delivery! Download the FoodCourt app to get started!"}
			brandArray={MarinaArray}
		/>
	);
};

export default CoKitchenMarina;
