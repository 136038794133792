/** @format */

import React from 'react';
import { useState, useEffect } from 'react';
import { browserName } from 'react-device-detect';
import Accordion from '../../components/accordion';
import { FaqArray } from '../../constants/arrays';
import Header from '../../components/header';
import Footer from '../../components/footer';
import Meta from '../../components/meta/Meta';

import { Input, TextArea } from '../../components/inputs';
import GetApp from '../landing/GetApp';
import Button from '../../components/button';
import DataService from '../../services/support';
import UserEmailReceived from '../../components/user-email-received/UserEmailReceived';

const FAQs = () => {
  const [open, setOpen] = useState(null);
  const [safari, setSafari] = useState(null);
  const [email, setEmail] = useState(null);
  const [message, setMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  useEffect(() => {
    if (browserName.includes('Safari')) {
      setSafari(true);
    } else {
      setSafari(false);
    }
  }, [safari]);

  const hideConfirm = () => setTimeout(() => setShowConfirm(false), 7000);
  useEffect(() => {
    hideConfirm();
  }, [showConfirm]);

  function handleAccordion(index) {
    if (open === index) {
      setOpen(null);
    } else {
      setOpen(index);
    }
  }
  const submitQuestion = async (e) => {
    e.preventDefault();
    window.rudderanalytics.track('Got More Questions Form Submitted', {
      email: email,
    });
    setLoading(true);
    let payload = {
      email: email,
      message: message,
    };

    let res = await DataService.postInternalData(
      'internal/email/support',
      payload
    );
    if (res) {
      setEmail('');
      setMessage('');
      setShowConfirm(true);
    }
    setLoading(false);
  };

  return (
    <div>
      <div className=''>
        <Meta
          title='FoodCourt FAQs - FoodCourt Help'
          url='https://www.getfoodcourt.com/faq'
          description='What is FoodCourt?. How do I order? Can I order from different restaurants? How do I top up my wallet? Can I earn on FoodCourt? How do I delete or edit orders in my basket? How do I delete or edit orders in my basket? How can I report or resolve issues & disputes? How can I report or resolve issues & disputes? What is the delivery fee? What are the available payment options? What are the opening hours? How do group orders work? How do I become a delivery partner? Can i get a refund? Can I Get Food On The Island, Food In VI, Food In Lekki'
        />

        <Header isWhite />
        <div className='text-[#00121F] flex justify-center pt-36 mdLg:pt-44 py-10 relative bg-[#E9F7FF]'>
          <div className='w-[90%] md:w-[75%] space-y-10 sm:space-y-14'>
            <div className='flex flex-wrap justify-between gap-4 items-center'>
              <span className='font-noirbold text-[#1A1A1A] text-2xl md:text-5xl w-full text-center'>
                Frequently asked <br /> questions
              </span>
            </div>

            <div className='space-y-4 pb-14'>
              {FaqArray.map((question, index) => {
                return (
                  <div>
                    <Accordion
                      key={index}
                      question={question.questions}
                      answer={question.answers}
                      open={open}
                      index={index}
                      onClick={() => handleAccordion(index)}
                    />
                    <hr className='border-1 border-[#A8DCFF] my-1 py-1 ' />
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
      <div className='mx-auto w-[88%] pb-20 md:pb-0 '>
        <div className='mx-auto text-center'>
          <h3 className='mt-14 text-2xl md:text-3xl font-noirmedium '>
            Got more questions, let us know
          </h3>
        </div>
        <div className=' mt-10 lg:mx-52 md:mx-24 md:mb-12 mb-8 mx-auto space-y-6'>
          <Input
            placeholder='Your email'
            label='Email'
            className='md:mb-2 mb-4 w-full'
            onChange={(e) => setEmail(e.target.value)}
            value={email}
            type='email'
          />
          <TextArea
            placeholder='Drop a message'
            className='w-full'
            label='Message'
            onChange={(e) => setMessage(e.target.value)}
            value={message}
          />
        </div>
        <div className='md:mb-2 mb-4'>
          <Button
            disabled={!(email || message)}
            loading={loading}
            text={'Send message'}
            height='h-[50px]'
            className={
              'bg-[#E60A2B] text-white-100 text-sm sm:text-lg w-[200px]  mx-auto rounded-[40px] text-manrope '
            }
            onClick={(e) => submitQuestion(e)}
          />
        </div>
      </div>
      {showConfirm && (
        <div
          className={` transition-all flex justify-center ${
            showConfirm
              ? 'opacity-100 md:translate-x-0'
              : 'opacity-0 translate-x-100'
          } md:fixed md:top-20 md:right-10 z-[1000000]`}
        >
          <UserEmailReceived />
        </div>
      )}
      <div className='md:my-44'>
        <GetApp safari={safari} />
      </div>
      <Footer />
    </div>
  );
};

export default FAQs;
