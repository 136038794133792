/** @format */

const LANDING = '/';
const FAQS = '/faq';
const NO_PAGE_FOUND = '*';
const BRANDS = '/brands';
const MENU = '/loveandluxe';
const JOLLOF_CO = '/brands/jollof-co';
const WINGS_KINGS = '/brands/wings-kings';
const WRAP_CITY = '/brands/wrap-city';
const CHOW_ASIAN = '/brands/chow-asian';
const SWEET_TREATS = '/brands/sweet-treats';
const MAMAS_KITCHEN = '/brands/mamas-kitchen';
const SUNNY_SIDE_CLUB = '/brands/sunny-side-club';
const FRANKIES = '/brands/frankies';
const GREEN_GOURMET = '/brands/green-gourmet';
const SUYA_CENTRAL = '/brands/suya-central';
const PASTRY_CORNER = '/brands/pastry-corner';
const FRESH_PRESS = '/brands/fresh-press';
const THE_NIGHT_TRAIN = '/brands/the-night-train';
const RED_PEPPER = '/brands/red-pepper';
const LAGOS_BREAKFAST_CLUB = '/brands/lagos-breakfast-club';
const USER_POLICY = '/userpolicy';
const PRIVACY_POLICY = '/privacy-policy';
const TERMS = '/terms-and-conditions';
const CONTACT = '/contact-us';
const ABOUT = '/about';
const SUPPORT = '/contact-us';
const LEKKI = '/locations/lekki';
const VI = '/locations/vi';
const IKOYI = '/locations/ikoyi';
const MARINA = '/locations/marina';
const EMAIL_VERIFICATION = 'verifymail/:token';

export {
  LANDING,
  MENU,
  FAQS,
  NO_PAGE_FOUND,
  BRANDS,
  USER_POLICY,
  PRIVACY_POLICY,
  TERMS,
  CONTACT,
  ABOUT,
  SUPPORT,
  JOLLOF_CO,
  LEKKI,
  VI,
  IKOYI,
  MARINA,
  WINGS_KINGS,
  CHOW_ASIAN,
  SWEET_TREATS,
  MAMAS_KITCHEN,
  SUNNY_SIDE_CLUB,
  FRANKIES,
  WRAP_CITY,
  GREEN_GOURMET,
  SUYA_CENTRAL,
  PASTRY_CORNER,
  FRESH_PRESS,
  THE_NIGHT_TRAIN,
  RED_PEPPER,
  LAGOS_BREAKFAST_CLUB,
  EMAIL_VERIFICATION,
};
