/** @format */

export const FrankiesBrandArray = [
  {
    meal: 'http://res.cloudinary.com/cokitchen/image/upload/v1650274567/a6uvjzziqz4fzig7wqp2.jpg',
    description:
      "Premium beef patty with egg, sausage, lettuce & special sauce. (specify if you don't want onions)",
    brandName: 'OG Burger',
    restaurant: 'Frankies',
    link: 'https://link.getfoodcourt.com/brands/IlZfQrgQXZ8wHJ/vKW8dSBNP7e2Z31ab4dBOuZqPU7U3VkHVWrhLt56G7QNmR/AV/brand/LVEFTO8XU8gwEJ+8K28dRUFB7eyb1FObvdMa7c7aBuFoAEGDWrhLt56G7QNmR/AV/menu/LVIPRb8RXZswHcjsfm8dS0YT7bSdjVqb4dUc652OB+9pVUXRWrhLt56G7QNmR/AV/share',
  },
  {
    meal: 'http://res.cloudinary.com/cokitchen/image/upload/v1650274577/vdufsdaizyvtcfzsebld.jpgg',
    description:
      "Double premium beef patty with cheese, tomatoes & lettuce in special sauce. (specify if you don't want onions)",
    brandName: 'Double Cheese Burger',
    restaurant: 'Frankies',
    link: 'https://link.getfoodcourt.com/brands/IlZfQrgQXZ8wHJ/vKW8dSBNP7e2Z31ab4dBOuZqPU7U3VkHVWrhLt56G7QNmR/AV/brand/LVEFTO8XU8gwEJ+8K28dRUFB7eyb1FObvdMa7c7aBuFoAEGDWrhLt56G7QNmR/AV/menu/fFoMQrlCDJgwGcizem8dHxRC7eyb3Fqb4oQY6p3fU7MyW0KAWrhLt56G7QNmR/AV/share',
  },
  {
    meal: 'http://res.cloudinary.com/cokitchen/image/upload/v1650274534/yuakf5z87mzwbatdfumi.jpg',
    description:
      'Minced brisket, sausage, bacon bits, onions, jalapeño with special sauce topped with parsley crumbs.',
    brandName: 'Loaded Hot Dog',
    restaurant: 'Frankies',
    link: 'https://link.getfoodcourt.com/brands/IlZfQrgQXZ8wHJ/vKW8dSBNP7e2Z31ab4dBOuZqPU7U3VkHVWrhLt56G7QNmR/AV/brand/LVEFTO8XU8gwEJ+8K28dRUFB7eyb1FObvdMa7c7aBuFoAEGDWrhLt56G7QNmR/AV/menu/KFcIROoQCMkwG5yzfG8dRUlG7bec31abvYBJuJ2KAuczBkDWWrhLt56G7QNmR/AV/share',
  },

  {
    meal: 'http://res.cloudinary.com/cokitchen/image/upload/v1650274548/jr2r3xnb1lj5ylgp3lte.jpg',
    description:
      'Loaded Hot dog with chopped cajun chicken, peppers & chilli mayo. ',
    brandName: 'Top Dog',
    restaurant: 'Frankies',
    link: 'https://link.getfoodcourt.com/brands/IlZfQrgQXZ8wHJ/vKW8dSBNP7e2Z31ab4dBOuZqPU7U3VkHVWrhLt56G7QNmR/AV/brand/LVEFTO8XU8gwEJ+8K28dRUFB7eyb1FObvdMa7c7aBuFoAEGDWrhLt56G7QNmR/AV/menu/e1tYEL0UXZ8wH5rue28dTUcU7eySiAKb54dOvsiKCuRjVhHYWrhLt56G7QNmR/AV/share',
  },
  {
    meal: 'http://res.cloudinary.com/cokitchen/image/upload/v1650274622/rungbcxscqhg2yqng5cj.jpg',
    description: 'French fries with chopped buffalo chicken, cheese & sauce.',
    brandName: 'Buffalo Chicken Fries',
    restaurant: 'Frankies',
    link: 'https://link.getfoodcourt.com/brands/IlZfQrgQXZ8wHJ/vKW8dSBNP7e2Z31ab4dBOuZqPU7U3VkHVWrhLt56G7QNmR/AV/brand/LVEFTO8XU8gwEJ+8K28dRUFB7eyb1FObvdMa7c7aBuFoAEGDWrhLt56G7QNmR/AV/menu/LFFeTbhDWc4wG5zrfm8dRENH7bfLjlCbs4Md5M+OB7ZjA0XSWrhLt56G7QNmR/AV/share',
  },
  {
    meal: 'http://res.cloudinary.com/cokitchen/image/upload/v1650274664/ehlbhfsugjeeklzs97cs.jpg',
    description: 'Smokey BBQ chicken wings (6 pc)',
    brandName: 'BBQ Chicken Wings ',
    restaurant: 'Frankies',
    link: 'https://link.getfoodcourt.com/brands/IlZfQrgQXZ8wHJ/vKW8dSBNP7e2Z31ab4dBOuZqPU7U3VkHVWrhLt56G7QNmR/AV/brand/LVEFTO8XU8gwEJ+8K28dRUFB7eyb1FObvdMa7c7aBuFoAEGDWrhLt56G7QNmR/AV/menu/IlEIFL0VWpkwGpq4Lm8dHhIR7bSajVKb5YMd6s2OAOZhB0CAWrhLt56G7QNmR/AV/share',
  },
];
