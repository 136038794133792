import React from "react";

const TestimonialCard = ({ username, review, logo }) => {
	return (
		<div className=" text-grey-100 text-base sm:text-xl md:text-2xl bg-white-100 flex flex-col justify-center  text-center gap-6 w-[95%] sm:w-[80%] mx-auto h-[100%] rounded-lg shadow-xl px-5 z-[10] relative ">
			<img
				src={logo}
				alt="FoodCourt App Reviews"
				className="w-8 absolute right-8 top-6 "
			/>
			<div className="text-lg">{username}</div>
			<div className=" px-6 md:px-10 ">{review}</div>
		</div>
	);
};

export default TestimonialCard;
