/** @format */

import React from 'react';
import { ReactComponent as RedBubbles } from '../../assets/img/red-bubbles.svg';
import { ReactComponent as CloseIcon } from '../../assets/icons/close.svg';
const UserEmailReceived = ({ onClose, subscribed }) => {
  return (
    <div
      className={` w-[270px] md:w-[380px] flex justify-center items-center   bg-white-100 shadow-xl relative rounded-xl `}
      style={{
        boxShadow: 'box-shadow: 0px 5px 30px 0px #6666664D ',
      }}
    >
      <RedBubbles className=' absolute bottom-0 left-0' />

      <div className=' flex flex-col gap-1 w-[90%] py-10 z-40 '>
        <div
          onClick={onClose}
          className='flex items-end justify-end cursor-pointer'
        >
          <CloseIcon />
        </div>
        <p className=' text-red-400 font-noirmedium text-base md:text-xl'>
          {subscribed
            ? 'You have successfully subcribed to our news letter'
            : 'Your message has been received✨'}
        </p>
        {!subscribed && (
          <p className=' text-black-300 '>
            Thanks for reaching out to us, we’ll get back to you soon
          </p>
        )}
      </div>
    </div>
  );
};

export default UserEmailReceived;
