/** @format */

import React from 'react';
import BrandLayout from '../../components/brandlayout/BrandLayout';
import { ChowAsianArray } from '../../constants/ChowAsianArray';
const ChowAsian = () => {
  return (
    <BrandLayout
      img={
        'https://res.cloudinary.com/olimeed/image/upload/q_auto:good/v1666863216/Chow_Asian_b7chtl.png'
      }
      brandName={'CHōW Asian'}
      igLink='https://www.instagram.com/chow_asian'
      brandLongDesc={
        'Enjoy a wholesome experience of the rich Asian culture through a variety of delicious and authentic Chinese dishes prepared to tingle your taste buds! Exclusively on FoodCourt.'
      }
      brandArray={ChowAsianArray}
    />
  );
};

export default ChowAsian;
