/** @format */

import React from "react";
import { Fade } from "react-reveal";
import DownloadButtons from "../download-buttons";

const BrandLayoutHeader = ({ safari, img, brandName, brandLongDesc }) => {
	return (
		<div className=" md:py-24 bg-blue-100">
			<div className=" sm:px-16 flex items-center justify-between flex-col md:flex-row space-y-8 pb-16 md:pb-0">
				<Fade right distance="150px">
					<div className=" px-4 md:px-0 pt-32 pb-10 md:pt-36 md:pb-20  flex flex-col space-y-14 w-full md:w-[50%]">
						<div className="space-y-5 sm:space-y-6">
							<div className="">
								<h3 className=" font-noirbold text-lg md:text-2xl lg:text-[50px] md:leading-[4rem]">
									{brandName}
								</h3>
							</div>

							<p className="text-sm sm:text-lg md:text-xl text-grey-300">
							{brandLongDesc}
							</p>
							<DownloadButtons safari={safari} />
						</div>
					</div>
				</Fade>

				<div className="w-[90%] md:w-[35%] transition-prop">
					<Fade left distance="150px">
						<img src={img} alt={`Order food online from ${brandName}`}  className="w-[100%]" />
					</Fade>
				</div>
			</div>
		</div>
	);
};

export default BrandLayoutHeader;
