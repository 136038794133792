/** @format */

import React from "react";
import BrandLayout from "../../components/brandlayout/BrandLayout";
import { JollofCoBrandArray } from "../../constants/JollofCoBrandArray";

const JollofCo = () => {
	return (
		<BrandLayout
			img={
				"https://res.cloudinary.com/olimeed/image/upload/q_auto:good/v1666863217/Jollof_Co_cm0wbv.png"
			}
			brandName={"Jollof & Co"}
			igLink={"https://www.instagram.com/jollof.co/"}
			brandLongDesc={"Experience Jollof in all its splendor! From the beloved smokey jollof rice to pasta, Asaro and much more we promise you a lunch full of relish! Exclusively on FoodCourt."}
			brandArray={JollofCoBrandArray}
		/>
	);
};

export default JollofCo;
