/** @format */

import React from "react";
import LocationLayout from "../../../components/locationlayout.js";
import Meta from "../../../components/meta/Meta.js";
import { LekkiArray } from "../../../constants/location/LekkiArray.js";
const CoKitchenLekki = () => {
	<Meta
		title="Food delivery in Lekki, Lagos- Enjoy delicious food | getfoodcourt"
		description="Order food online from your favourite restaurants that deliver in Lekki, Lagos on getfoodcourt. Rice, jollof rice"
	/>;
	return (
		<LocationLayout
			locationName="Lekki"
			LocationBg={
				"https://res.cloudinary.com/olimeed/image/upload/q_auto:good/v1667274035/780C96BB-3261-41D8-8C66-862630D7084F-min_pbb6fd.png"
			}
			locationDesc ={"If you live or work in Lekki, we already know you enjoy a high standard with several exotic restaurants and cuisines around you to get well-prepared meals. However, leaving your place of convenience to get food can be time consuming and less preferable. With FoodCourt, you can order food from where you are and be sure to get the best food delivery service Lekki has to offer.  All you need to do is download the FoodCourt app, select from the best cuisines and restaurants to order your favorite meals, snacks, drinks and lifestyle essentials and have it delivered to you in minutes! "}
			brandArray={LekkiArray}
		/>
	);
};

export default CoKitchenLekki;
