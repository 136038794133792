/** @format */

import React from 'react';
import { useState } from 'react';
import Button from '../button';
import BrandCard from '../card/BrandCard';
import { Input } from '../inputs';
import DataService from '../../services/support';
import UserEmailReceived from '../user-email-received/UserEmailReceived';

const LocationLayoutContent = ({ brandArray, locationName }) => {
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);

  const onConfirm = async () => {
    if (!email) return;
    setLoading(true);
    try {
      const payload = {
        email,
      };
      const res = await DataService.postInternalData(
        'newsletter',
        payload,
        '',
        true
      );
      if (res) {
        setEmail('');
        setShowConfirm(true);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className=' py-16 sm:py-24 flex justify-center flex-col px-6 md:px-16'>
      <h3 className=' text-center font-semibold text-2xl md:text-3xl '>
        Special offers ✨
      </h3>
      <div className='py-16 sm:pb-24 gap-10 flex flex-wrap justify-center'>
        {brandArray.map((brand) => {
          return (
            <BrandCard
              meal={brand.meal}
              brandLogo={brand.brandLogo}
              description={brand.description}
              brandName={brand.brandName}
              link={brand.link}
              isBrandDetails
            />
          );
        })}
      </div>
      <h3 className=' text-center font-semibold text-2xl md:text-3xl py-5'>
        Subscribe to our newsletter
      </h3>

      <p className='  text-grey-300 text-center w-[80%] mx-auto text-sm sm:text-lg md:text-xl md:leading-8 pt-2'>
        Get monthly updates of our menu, and be the first to know when we open
        new locations. Learn about our products, services and get exciting
        discounts ahead of time
      </p>

      <div className='flex gap-7 flex-wrap items-end justify-center mt-[38px] w-[90%] mx-auto mb-12 '>
        <Input
          onChange={(e) => setEmail(e.target.value)}
          value={email}
          className='h-[45px] w-full md:w-[500px]'
          label={'Email'}
          placeholder={'Enter your email here'}
        />
        <Button
          text={'Subscribe'}
          loading={loading}
          disabled={!email}
          height='h-[45px]'
          className={
            'w-[200px] bg-red-400 text-white-100 text-base sm:text-lg font-semibold mt-4'
          }
          onClick={() => {
            window.rudderanalytics.track('Subscribe Newsletter Form Submit', {
              location: locationName,
            });
            onConfirm();
          }}
        />
      </div>

      {showConfirm && (
        <div
          className={` transition-all flex justify-center ${
            showConfirm
              ? 'opacity-100 md:translate-x-0'
              : 'opacity-0 translate-x-100'
          } md:fixed md:top-20 md:right-10 z-[1000000]`}
        >
          <UserEmailReceived subscribed onClose={() => setShowConfirm(false)} />
        </div>
      )}
    </div>
  );
};

export default LocationLayoutContent;
