import axios from 'axios';
const API_KEY = process.env.REACT_APP_API_KEY;
const API_URL = process.env.REACT_APP_API_URL;
const ORDER_API_URL = process.env.REACT_APP_ORDER_API_URL;

class DataService {
  /***********************************Get data********************* */
  async postInternalData(url, data, token, useOrderApi) {
    const baseUrl = useOrderApi ? ORDER_API_URL + url : API_URL + url;
    return axios
      .post(baseUrl, data, {
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json',
          'x-api-key': API_KEY,
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        return response;
      });
  }
}

export default new DataService();
