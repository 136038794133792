/** @format */

import React from "react";
import BrandLayout from "../../components/brandlayout/BrandLayout";
import { GreenGourmetBrandArray } from "../../constants/GreenGourmetBrandArray";
const GreenGourmet = () => {
  return (
    <BrandLayout
      img={
        "https://d3u7b9fq2opvwp.cloudfront.net/upload-service/16831105476905e0add5d-5e8c-4e02-81d6-cbecad5972a2"
      }
      brandName={"Good Greens"}
      igLink="https://www.instagram.com/_greengourmet"
      brandLongDesc={
        "Welcome to Good Greens, a delicious selection of natural smoothies, parfaits, salads, sandwiches and wraps that are sure to give you the boost you need!Exclusive on the FoodCourt app!"
      }
      brandArray={GreenGourmetBrandArray}
    />
  );
};

export default GreenGourmet;
