/** @format */

import React from 'react';
import BrandLayout from '../../components/brandlayout/BrandLayout';
import { WingKingsBrandArray } from '../../constants/WingKingsBrandArray';

const WingKings = () => {
  return (
    <BrandLayout
      img={
        'https://res.cloudinary.com/olimeed/image/upload/q_auto:good/v1666863214/Wing_Kings_ttogss.png'
      }
      brandName={'Wing Kings'}
      igLink='https://www.instagram.com/wingkings.co'
      brandLongDesc={
        'Satisfy your cravings with thoroughly seasoned, well-garnished and delicious chicken wings! Exclusively on FoodCourt.'
      }
      brandArray={WingKingsBrandArray}
    />
  );
};

export default WingKings;
