/** @format */

import React from "react";

const Accordion = ({ question, answer, open, index, onClick }) => {
	return (
		<div
			className={`text-[#00121F] rounded-md py-6 px-6 flex items-center  space-y-6 faqs h-20  sm:h-14 overflow-hidden transition-height ${
				open === index && "h-auto sm:h-[300px] md:h-[220px] lg:h-[220px]"
			}`}
		>
			<div className="w-full space-y-6">
				<div
					className=" text-[#00121F] font-semibold text-lg  items-center flex justify-between gap-2 cursor-pointer"
					onClick={onClick}
				>
					<div className="w-64 sm:w-full"> {question} </div>
					{open === index ? (
						<svg
							width="35"
							height="35"
							viewBox="0 0 40 41"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M19.9997 37.1667C29.1663 37.1667 36.6663 29.6667 36.6663 20.5C36.6663 11.3334 29.1663 3.83337 19.9997 3.83337C10.833 3.83337 3.33301 11.3334 3.33301 20.5C3.33301 29.6667 10.833 37.1667 19.9997 37.1667Z"
								stroke="#00121F"
								stroke-linecap="round"
								stroke-linejoin="round"
							/>
							<path
								d="M15.2832 25.2167L24.7165 15.7833"
								stroke="#00121F"
								stroke-linecap="round"
								stroke-linejoin="round"
							/>
							<path
								d="M24.7165 25.2167L15.2832 15.7833"
								stroke="#00121F"
								stroke-linecap="round"
								stroke-linejoin="round"
							/>
						</svg>
					) : (
						<svg
							width="35"
							height="35"
							viewBox="0 0 40 41"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M19.9997 37.1667C29.1663 37.1667 36.6663 29.6667 36.6663 20.5C36.6663 11.3334 29.1663 3.83337 19.9997 3.83337C10.833 3.83337 3.33301 11.3334 3.33301 20.5C3.33301 29.6667 10.833 37.1667 19.9997 37.1667Z"
								stroke="#00121F"
								stroke-linecap="round"
								stroke-linejoin="round"
							/>
							<path
								d="M13.333 20.5H26.6663"
								stroke="#00121F"
								stroke-linecap="round"
								stroke-linejoin="round"
							/>
							<path
								d="M20 27.1667V13.8334"
								stroke="#00121F"
								stroke-linecap="round"
								stroke-linejoin="round"
							/>
						</svg>
					)}
				</div>

				{open === index && (
					<div
						className={` text-base text-[#555A5E] rounded-md  leading-8 drop-shadow-xl ${
							open === index && "h-auto py-4 flex items-center"
						}`}
					>
						{answer}
					</div>
				)}
			</div>
		</div>
	);
};

export default Accordion;
