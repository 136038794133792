/** @format */

import React from "react";

const Input = ({ placeholder, className, onChange, label, value, type }) => {
	return (
		<div className="space-y-3">
			<label className="font-noirmedium ">{label}</label>
			<br />
			<input
				value={value}
				val
				placeholder={placeholder}
				onChange={onChange}
				type={type}
				className={`border border-[#E0DADB] p-4 w-full rounded-lg  ${className}`}
			/>
		</div>
	);
};
const TextArea = ({ placeholder, onChange, className, label, value }) => {
	return (
		<div>
			<label className="font-noirmedium mb-2">{label}</label>
			<br />

			<textarea
				value={value}
				val
				placeholder={placeholder}
				onChange={onChange}
				className={`resize-none h-[279px] border border-[#E0DADB] my-2 p-4 w-full rounded-lg  ${className}`}
			/>
		</div>
	);
};

export { Input, TextArea };
