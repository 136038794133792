/** @format */

import "./App.css";
import { Routes, Route } from "react-router-dom";
import {
	BRANDS,
	CHOW_ASIAN,
	CONTACT,
	EMAIL_VERIFICATION,
	FAQS,
	FC_SHOP,
	FRANKIES,
	FRESH_PRESS,
	GREEN_GOURMET,
	IKOYI,
	JOLLOF_CO,
	LAGOS_BREAKFAST_CLUB,
	LANDING,
	LEKKI,
	MAMAS_KITCHEN,
	MARINA,
	NO_PAGE_FOUND,
	PASTRY_CORNER,
	PRIVACY_POLICY,
	RED_PEPPER,
	SUNNY_SIDE_CLUB,
	SUYA_CENTRAL,
	SWEET_TREATS,
	TERMS,
	THE_NIGHT_TRAIN,
	USER_POLICY,
	VI,
	WINGS_KINGS,
	WRAP_CITY,
	MENU,
} from "./routes";
import FAQs from "./pages/FAQs";
import LandingPage from "./pages/landing";
import NoPageFound from "./pages/no-page-found";
import ScrollToTop from "./utils/ScrollToTop";
import MeetTheBrands from "./pages/brands";
import UserPolicy from "./pages/user-policy";
import PrivacyPolicy from "./pages/privacy-policy";
import Terms from "./pages/terms";
import JollofCo from "./pages/brands/JollofCo";
import CoKitchenLekki from "./pages/locations/lekki";
import CoKitchenIkoyi from "./pages/locations/ikoyi";
import CoKitchenVI from "./pages/locations/vi";
import CoKitchenMarina from "./pages/locations/marina";
import ChowAsian from "./pages/brands/ChowAsian";
import Frankies from "./pages/brands/Frankies";
import MamasKitchen from "./pages/brands/MamasKitchen";
import SunnySideClub from "./pages/brands/SunnySideClub";
import SweetTreats from "./pages/brands/SweetTreats";
import WrapCity from "./pages/brands/WrapCity";
import WingKings from "./pages/brands/WingKings";
import GreenGourmet from "./pages/brands/GreenGourmet";
import SuyaCentral from "./pages/brands/SuyaCentral";
import PastryCorner from "./pages/brands/PastryCorner";
import FreshPress from "./pages/brands/FreshPress";
import TheNightTrain from "./pages/brands/TheNightTrain";
import RedPepper from "./pages/brands/RedPepper";
import LagosBreakfastClub from "./pages/brands/LagosBreakfastClub";
import Contact from "./pages/contact-us";
import EmailVerification from "./pages/email-verification";
import Menu from "./pages/menu";
function App() {
	return (
		<div className="font-noiregular relative">
			<ScrollToTop />
			<Routes>
				<Route path={MENU} element={<Menu />} />
				<Route path={LANDING} element={<LandingPage />} />
				<Route path={FAQS} element={<FAQs />} />
				<Route path={BRANDS} element={<MeetTheBrands />} />
				<Route path={JOLLOF_CO} element={<JollofCo />} />
				<Route path={FRANKIES} element={<Frankies />} />
				<Route path={MAMAS_KITCHEN} element={<MamasKitchen />} />
				<Route path={CHOW_ASIAN} element={<ChowAsian />} />
				<Route path={SUNNY_SIDE_CLUB} element={<SunnySideClub />} />
				<Route path={SWEET_TREATS} element={<SweetTreats />} />
				<Route path={WINGS_KINGS} element={<WingKings />} />
				<Route path={WRAP_CITY} element={<WrapCity />} />
				<Route path={GREEN_GOURMET} element={<GreenGourmet />} />
				<Route path={SUYA_CENTRAL} element={<SuyaCentral />} />
				<Route path={PASTRY_CORNER} element={<PastryCorner />} />
				<Route path={LAGOS_BREAKFAST_CLUB} element={<LagosBreakfastClub />} />
				<Route path={FRESH_PRESS} element={<FreshPress />} />
				<Route path={THE_NIGHT_TRAIN} element={<TheNightTrain />} />
				<Route path={RED_PEPPER} element={<RedPepper />} />
				<Route path={USER_POLICY} element={<UserPolicy />} />
				<Route path={PRIVACY_POLICY} element={<PrivacyPolicy />} />
				<Route path={TERMS} element={<Terms />} />
				<Route path={LEKKI} element={<CoKitchenLekki />} />
				<Route path={IKOYI} element={<CoKitchenIkoyi />} />
				<Route path={VI} element={<CoKitchenVI />} />
				<Route path={MARINA} element={<CoKitchenMarina />} />
				<Route path={CONTACT} element={<Contact />} />
				<Route path={EMAIL_VERIFICATION} element={<EmailVerification />} />
				<Route path={NO_PAGE_FOUND} element={<NoPageFound />} />
			</Routes>
		</div>
	);
}

export default App;
