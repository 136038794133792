/** @format */

import React, { useEffect, useState } from "react";
import { browserName } from "react-device-detect";
import { Link, NavLink, useLocation } from "react-router-dom";
import { BRANDS, CONTACT, FAQS } from "../../routes";
import MobileNavImage from "../../assets/img/FoodCourt_Mobile.webp";
import DownloadButtons from "../download-buttons";
const Navbar = ({ openNav }) => {
  const [safari, setSafari] = useState(false);

  useEffect(() => {
    if (browserName.includes("Safari")) {
      setSafari(true);
    } else {
      setSafari(false);
    }
  }, [safari]);

  const { pathname } = useLocation();
  return (
    <div
      className={`z-[1000] fixed transition-prop  drop-shadow-2xl  left-0  w-[100vw] overflow-hidden transition-height ${
        openNav ? "block opacity-1 h-[100vh]" : "hidden opacity-0 h-0"
      }  `}
      id="nav-bar-modal"
    >
      <div className=" w-[100vw] px-6 text-lg bg-black-100  md:text-xl  flex flex-col justify-evenly items-start h-[100%]">
        <div className="flex flex-col space-y-6 items-start">
          <NavLink
            to={BRANDS}
            className={`hover:underline hover:underline-offset-2  hover:scale-[1.1]  transition-prop ${
              pathname === BRANDS ? "text-red-400" : "text-white-100"
            }`}
          >
            {" "}
            Restaurants{" "}
          </NavLink>
          <Link
            to={FAQS}
            className={`hover:underline hover:underline-offset-2  hover:scale-[1.1]  transition-prop ${
              pathname === FAQS ? "text-red-400" : "text-white-100"
            }`}
          >
            FAQS
          </Link>
          <Link
            to={CONTACT}
            className={`hover:underline hover:underline-offset-2  hover:scale-[1.1]  transition-prop ${
              pathname === CONTACT ? "text-red-400" : "text-white-100"
            }`}
          >
            Support
          </Link>
        </div>
        <DownloadButtons safari={safari} />
        <div
          className="h-[180px] w-full mb-10 smm:hidden"
          style={{
            backgroundImage: `url(${MobileNavImage})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
          }}
        ></div>
      </div>
    </div>
  );
};

export default Navbar;
