/** @format */

import React from "react";
import LocationLayout from "../../../components/locationlayout.js";
import Meta from "../../../components/meta/Meta";
import { IkoyiArray } from "../../../constants/location/IkoyiArray.js";

const CoKitchenIkoyi = () => {
	<Meta
		title="Food delivery in Ikoyi, Lagos- Enjoy delicious food | getfoodcourt"
		description="Order food online from your favourite restaurants that deliver in Ikoyi, Lagos on getfoodcourt. Rice, jollof rice"
	/>;
	return (
		<LocationLayout
			locationName="Ikoyi"
			LocationBg={
				"https://res.cloudinary.com/olimeed/image/upload/q_auto:good/v1667275441/Food_Court_c00476_kprgl4.jpg"
			}
			locationDesc ={"Ikoyi is widely regarded as the most affluent area in Lagos. It is also the go-to place for young and high-earning professionals. If you live in Ikoyi, we already know you don’t like stress. We also know how discouraging the Lagos traffic can be and more often than not, work can keep you from satisfying your cravings. This is where FoodCourt, the best food delivery service in Ikoyi comes in. We’re here to ease your stress and all you need to do is download the FoodCourt app, select from the best restaurants to order your favorite meals, snacks, drinks and lifestyle essentials and have it delivered to you in minutes! "}
			brandArray={IkoyiArray}
		/>
	);
};

export default CoKitchenIkoyi;
