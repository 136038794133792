/** @format */

import React from 'react';
import GooglePlay from '../../assets/img/googlePlayBadge.png';
import AppStore from '../../assets/img/app-store.png';
const DownloadButtons = ({ safari }) => {
  return (
    <div className='flex gap-4 sm:gap-6 md:pt-4 flex-wrap'>
      {safari ? (
        <>
          <a
            href='https://foodcourt.onelink.me/hRlV/Fcweb'
            target='_blank'
            rel='noreferrer'
            onClick={() =>
              window.rudderanalytics.track('App Install Click', {
                app_type: 'ios',
              })
            }
          >
            <img
              src={AppStore}
              alt='Download App Button - Order Food, Grocery, Snacks, Wine, Drinks On The Lagos Island,Ibeju Lekki, Victoria Island'
              className='hover:scale-110 transition-prop shadow-md h-10 '
            />
          </a>
        </>
      ) : (
        <>
          <a
            href='https://foodcourt.onelink.me/hRlV/Fcweb'
            target='_blank'
            rel='noreferrer'
            onClick={() =>
              window.rudderanalytics.track('App Install Click', {
                app_type: 'ios',
              })
            }
          >
            <img
              src={AppStore}
              alt='Download App Button - Order Food, Grocery, Snacks, Wine, Drinks On The Lagos Island,Ibeju Lekki, Victoria Island'
              className=' h-10 hover:scale-110 transition-prop shadow-md'
            />
          </a>
        </>
      )}
      <a
        href='https://foodcourt.onelink.me/hRlV/Fcweb'
        target='_blank'
        rel='noreferrer'
        onClick={() =>
          window.rudderanalytics.track('App Install Click', {
            app_type: 'Android',
          })
        }
      >
        <img
          src={GooglePlay}
          alt='Download App Button - Order Food, Grocery, Snacks, Wine, Drinks On The Lagos Island,Ibeju Lekki, Victoria Island'
          className='h-[40px] w-[120px] xs:w-[130px] hover:scale-110 transition-prop shadow-md'
        />
      </a>
    </div>
  );
};

export default DownloadButtons;
