/** @format */

export const SunnySideClubBrandArray = [
  {
    meal: 'http://res.cloudinary.com/cokitchen/image/upload/v1629807721/optimized_qdijxr.jpg',
    description:
      'Naija breakfast. Choice of yam, potatoes or plantain, sauteed vegtables, egg sauce & sausage.',
    brandName: 'Full Lagos Breakfast',
    restaurant: 'Sunny Side Club',
    link: 'https://link.getfoodcourt.com/brands/IlZfQrgQXZ8wHJ/vKW8dSBNP7e2Z31ab4dBOuZqPU7U3VkHVWrhLt56G7QNmR/AV/brand/KlYORuoWXM0wEMrodG8dGUUU7e2YiVubttNG6Z6JAeZpUUXSWrhLt56G7QNmR/AV/menu/KVcNQ+lADpgwH83uL28dGUNE7eyY1Vabs4dG6MvZV+MwVBXQWrhLt56G7QNmR/AV/share',
  },
  {
    meal: 'http://res.cloudinary.com/cokitchen/image/upload/v1629810071/optimized_aodlkl.jpg',
    description:
      'Delicious french toast served with choice of eggs, bacon & sausage.',
    brandName: 'French Toast Breakfast',
    restaurant: 'Sunny Side Club',
    link: 'https://link.getfoodcourt.com/brands/IlZfQrgQXZ8wHJ/vKW8dSBNP7e2Z31ab4dBOuZqPU7U3VkHVWrhLt56G7QNmR/AV/brand/KlYORuoWXM0wEMrodG8dGUUU7e2YiVubttNG6Z6JAeZpUUXSWrhLt56G7QNmR/AV/menu/KVcLRL0SXJUwT5i4K28dGUcR7beY2wGbtoAfuM7ZUe4yVkDVWrhLt56G7QNmR/AV/share',
  },
  {
    meal: 'http://res.cloudinary.com/cokitchen/image/upload/v1629808523/optimized_bnrhyu.jpg',
    description: 'Waffles served with choice of eggs, bacon & sausage.',
    brandName: 'Waffle Breakfast',
    restaurant: 'Sunny Side Club',
    link: 'https://link.getfoodcourt.com/brands/IlZfQrgQXZ8wHJ/vKW8dSBNP7e2Z31ab4dBOuZqPU7U3VkHVWrhLt56G7QNmR/AV/brand/KlYORuoWXM0wEMrodG8dGUUU7e2YiVubttNG6Z6JAeZpUUXSWrhLt56G7QNmR/AV/menu/I1daQ+oZD8gwT5jseW8dTkcV7bTI2AGbtdBK75qPA+U3UEPSWrhLt56G7QNmR/AV/share',
  },
  {
    meal: 'http://res.cloudinary.com/cokitchen/image/upload/v1643614098/Frankies00612_afuxqo.jpg',
    description:
      'Loaded breakfast burger. Beef patty, sausages, eggs & lettuce. (Specify if you want onions)',
    brandName: 'Breakfast Burger',
    restaurant: 'Sunny Side Club',
    link: 'https://link.getfoodcourt.com/brands/IlZfQrgQXZ8wHJ/vKW8dSBNP7e2Z31ab4dBOuZqPU7U3VkHVWrhLt56G7QNmR/AV/brand/KlYORuoWXM0wEMrodG8dGUUU7e2YiVubttNG6Z6JAeZpUUXSWrhLt56G7QNmR/AV/menu/IgUNROhED5wwTZq4f28dSRVH7e3I2lCb4Y5Kuc7ZBOM3UkTZWrhLt56G7QNmR/AV/share',
  },
  {
    meal: 'http://res.cloudinary.com/cokitchen/image/upload/v1629810050/optimized_cekeav.jpg',
    description: 'Classic loaded club sandwich. Served with choice of fries.',
    brandName: 'Club Sandwich',
    restaurant: 'Sunny Side Club',
    link: 'https://link.getfoodcourt.com/brands/IlZfQrgQXZ8wHJ/vKW8dSBNP7e2Z31ab4dBOuZqPU7U3VkHVWrhLt56G7QNmR/AV/brand/KlYORuoWXM0wEMrodG8dGUUU7e2YiVubttNG6Z6JAeZpUUXSWrhLt56G7QNmR/AV/menu/IlFaE+wUU8kwSMu5f28dTUQW7e2eiFOb4NdJvcyIAu9iVkHRWrhLt56G7QNmR/AV/share',
  },
  {
    meal: 'http://res.cloudinary.com/cokitchen/image/upload/v1629810027/optimized_d1rqvi.jpg',
    description:
      'Loaded breakfast burrito with minced meat, tomatoes, cilantro, scambled eggs, baked beans & sauce. Served with choice of fries.',
    brandName: 'Breakfast Burrito',
    restaurant: 'Sunny Side Club',
    link: 'https://link.getfoodcourt.com/brands/IlZfQrgQXZ8wHJ/vKW8dSBNP7e2Z31ab4dBOuZqPU7U3VkHVWrhLt56G7QNmR/AV/brand/KlYORuoWXM0wEMrodG8dGUUU7e2YiVubttNG6Z6JAeZpUUXSWrhLt56G7QNmR/AV/menu/e1UITO9AWpUwG5y4L28dH0dE7bSd3AWbsNRH7MbbBOc0A0PYWrhLt56G7QNmR/AV/share',
  },
];
