/** @format */

import GoodFood from '../assets/img/GoodFood.jpg';
import MultiBrandOrdering from '../assets/img/MultiBrandOrdering.jpg';
import GroupOrderings from '../assets/img/GroupOrdering.jpg';

import TwitterLogo from '../assets/img/twitter_blue.png';
import AppStoreLogo from '../assets/img/appStoreLogo.png';
import PlayStoreLogo from '../assets/img/playstorelogo.png';

import JollofMeal from '../assets/img/JollofMeall.jpg';
import ChowMeal from '../assets/img/Chow_AsianMeal.webp';
import FrankiesMeal from '../assets/img/FrankiesMeal.webp';
import SweetTreatMeal from '../assets/img/SweetTreatMeal.webp';
import SunnySideMeal from '../assets/img/SunnySideClubMeall.jpg';
import MamasMeal from '../assets/img/MamasMeall.jpg';
import WingKingsMeal from '../assets/img/WingKingsMeal.png';
import Redpepper from '../assets/img/Red-Pepper.jpg';

import Jollofco from '../assets/img/brands/jollof_co.png';
import Mamas from '../assets/img/brands/mamas_kitchen.png';
import Chow from '../assets/img/brands/chow_asian.png';
import SweetTreats from '../assets/img/brands/sweet_treats.png';
import Red from '../assets/img/RedPepper-Logo.png';
import Pastry from '../assets/img/Pastry-Corner-Logo.png';
import SunnySide from '../assets/img/brands/sunny_side_club.png';
import Frankies from '../assets/img/brands/frankies.png';
import WingsKings from '../assets/img/brands/wings_kings.png';
import Instagram from '../assets/img/011-instagram-white.png';
import Facebook from '../assets/img/001-facebook white.png';
import Twitter from '../assets/img/013-twitter-white.png';
import Linkedin from '../assets/img/010-linkedin white.png';
import Tiktok from '../assets/img/TIktok.png';
import {
  JOLLOF_CO,
  MAMAS_KITCHEN,
  WRAP_CITY,
  CHOW_ASIAN,
  SWEET_TREATS,
  FRANKIES,
  SUNNY_SIDE_CLUB,
  WINGS_KINGS,
  RED_PEPPER,
  GREEN_GOURMET,
  PASTRY_CORNER,
  FRESH_PRESS,
  THE_NIGHT_TRAIN,
  SUYA_CENTRAL,
  LAGOS_BREAKFAST_CLUB,
} from '../routes';

const Lgas = [
  'Agege',
  'Ajeromi-Ifelodun',
  'Alimosho',
  'Amuwo-Odofin',
  'Badagry',
  'Apapa',
  'Epe',
  'Eti Osa',
  'Ibeju-Lekki',
  'Ifako-Ijaiye',
  'Ikeja',
  'Ikorodu',
  'Kosofe',
  'Lagos Island',
  'Mushin',
  'Lagos Mainland',
  'Ojo',
  'Oshodi-Isolo',
  'Shomolu',
  'Surulere Lagos State',
];

const FeatureContentArray = [
  {
    title: 'Good food only',
    details:
      'Enjoy a curation of chef prepared meals from the best delivery brands',
    image: GoodFood,
    delay: '0',
  },
  {
    title: 'Multi-Restaurants  ordering',
    details: 'Order across multiple restaurants in a single order!',
    image: MultiBrandOrdering,
    delay: '500',
  },

  {
    title: 'Group ordering',
    details:
      'Order together with friends from different devices & split the bill.',
    image: GroupOrderings,
    delay: '1500',
  },
];

const BrandsImage = [
  { img: Jollofco },
  { img: Mamas },
  { img: Chow },
  { img: SweetTreats },
  {
    img: 'https://res.cloudinary.com/olimeed/image/upload/v1659957907/Wrap_City_Logo_2_gq3nds.png',
  },
  {
    img: 'http://res.cloudinary.com/cokitchen/image/upload/v1687121133/en33r0svvyixf45jjufk.jpg',
  },
  { img: SunnySide },
  { img: Frankies },
  { img: WingsKings },
];

const Socials = [
  {
    link: 'https://www.instagram.com/foodcourt_app/',
    img: Instagram,
    name: 'Instagram',
  },
  {
    link: 'https://web.facebook.com/getfoodcourt/',
    img: Facebook,
    name: 'Facebook',
  },
  {
    link: 'https://www.twitter.com/foodcourt_app',
    img: Twitter,
    name: 'Twitter',
  },
  {
    link: ' https://www.linkedin.com/company/joinco-kitchen/',
    img: Linkedin,
    name: 'Linkedin',
  },
  {
    link: 'https://www.tiktok.com/@foodcourt_app',
    img: Tiktok,
    name: 'Tiktok',
  },
];

const FaqArray = [
  {
    questions: 'What is FoodCourt?',
    answers:
      'FoodCourt is an on-demand convenience app that lets you order from a curation of the best food delivery brands & shops. Now you can order jollof rice, a burger, pounded yam & a bottle of wine all in a single order!',
  },
  {
    questions: 'How do I order?',
    answers:
      'Once you download FoodCourt, you can place an order by simply tapping on the restaurant of your choice, selecting an item and clicking “Add to Basket” for Food. For Shop, simply visit the shop section on your bottom nav bar, then click on the blue + button to add items to your cart. Click “View Basket” to complete your order.',
  },
  {
    questions: ' Can I order from different restaurants?',
    answers:
      'YES! With FoodCourt you can order all your favorite dishes, snacks & more across brands, in a single basket.',
  },

  {
    questions: 'How do I top up my wallet?	',
    answers:
      'You can top up your wallet by visiting the “Wallet” page. This page can be found in your menu page at the top right corner. You can add to your wallet by simply selecting the amount you want to add then paying with a new or saved bank card.',
  },
  {
    questions: 'Can I earn on FoodCourt?',
    answers:
      'You can earn credit in your wallet by sharing your referral code with your friends and family. Sharing your referral code gives them 10% off when they spend up to N2,000 & gives you N200 every time a new user uses your code!',
  },
  {
    questions: 'How do I delete or edit orders in my basket?',
    answers:
      'You can delete or edit items in your basket by swiping them left to reveal options',
  },
  {
    questions: 'How can I report or resolve issues & disputes?',
    answers:
      'You can email us at hello@getfoodcourt.com or visit the help/ feedback page on the app to see the available call & whatsapp lines',
  },
  {
    questions: 'What is the delivery fee?',
    answers:
      'The delivery fee is determined by the distance between you & the restaurant. Our delivery fees currently go from 390 – 850 naira & may increase as much as 1.5x during a surge. Surges are caused by irregular factors like heavy traffic, heavy rainfall, fuel scarcity, high order volumes at the restaurant, etc.  ',
  },
  {
    questions: 'What are the available payment options?',
    answers:
      'We are 100% cashless! You can pay for your order via paystack (card) or bank transfer. Please note that for bank transfers, your payment must be confirmed before your order is prepared. ',
  },
  {
    questions: 'What are the opening hours? ',
    answers:
      'Most brands on the app open between 8am – 12am (midnight) on Monday – Saturday & 10am – 11pm on Sundays.  ',
  },
  {
    questions: 'How do group orders work?',
    answers:
      ' Group orders from FoodCourt lets you order together with friends & family across brands from your different devices. With group order you can split the bill or even pay for everyone with the help of a spending limit. The host pays the delivery fee & delivery fees are doubled when group order participants are over 12. ',
  },
  {
    questions: ' How do I become a delivery partner?',
    answers:
      ' Email us at delivery@getfoodcourt.com and we will respond with more information.',
  },
  {
    questions: ' I can’t see my question here. I need more information.',
    answers:
      'You can email us at hello@getfoodcourt.com with your phone number + your question and we will give you a call ASAP with the answer. You can also visit the help/ feedback page on the app to see the available call & whatsapp lines. ',
  },
];

const TestimonialArray = [
  {
    username: '@___amah',
    review:
      'You can order from @foodcourt_app  daily for a month and still won’t repeat  meal! So many delicious varieties to  choose from!',
    logo: TwitterLogo,
  },
  {
    username: 'Mercy Igbe',
    review:
      'This app is the best thing that has happened to me in a while. All the meals are top notch and delivery time is really impressive. Thank you for doing your job well.',

    logo: AppStoreLogo,
  },
  {
    username: 'In finix',
    review:
      "Great app, very user friendly. The meals are delicious and the packaging is top notch. I love that their environmental friendly packaging aswell. Delivery is always fast. One of the best food apps I've used in Lagos.",
    logo: PlayStoreLogo,
  },
  {
    username: 'Mir Fontana',
    review:
      'Seamless experience from start to finish! Makes me so excited to see ❤️❤️❤️',
    logo: AppStoreLogo,
  },

  {
    username: 'Chidinma Vivian Udeh',
    review:
      'Amazing service!! Even after a little issue with my order because of the rider, got compensated and I even got a star treatment. Definitely recommend over and over again, Thank you, FoodCourt.',
    logo: PlayStoreLogo,
  },

  {
    username: '@somitic',
    review: '@foodcourt_app anyday 🔥🔥🔥',
    logo: TwitterLogo,
  },
];

const BrandsArray = [
  {
    meal: JollofMeal,
    brandLogo: Jollofco,
    description: 'Everyday naija favourites',
    brandName: 'Jollof & Co.',
    link: JOLLOF_CO,
  },
  {
    meal: WingKingsMeal,
    brandLogo: WingsKings,
    description: 'Good chicken & more!',
    brandName: 'Wing Kings',
    link: WINGS_KINGS,
  },
  {
    meal: ChowMeal,
    brandLogo: Chow,
    description: 'Delicious & authentic Asian flavours',
    brandName: 'CHōW Asian',
    link: CHOW_ASIAN,
  },
  {
    meal: SunnySideMeal,
    brandLogo: SunnySide,
    description: 'Breakfast to start your day right!',
    brandName: 'Sunny Side Club ',
    link: SUNNY_SIDE_CLUB,
  },
  {
    meal: MamasMeal,
    brandLogo: Mamas,
    description: 'Mama’s special native dishes',
    brandName: 'Mama’s Kitchen',
    link: MAMAS_KITCHEN,
  },
  {
    meal: SweetTreatMeal,
    brandLogo: SweetTreats,
    description: 'All day treats',
    brandName: 'Sweet Treats',
    link: SWEET_TREATS,
    noIg: true,
  },
  {
    meal: FrankiesMeal,
    brandLogo: Frankies,
    description: 'Gourmet burgers. hotdogs & more!',
    brandName: 'Frankies',
    link: FRANKIES,
  },
  {
    meal: 'https://d3u7b9fq2opvwp.cloudfront.net/upload-service/16831105476905e0add5d-5e8c-4e02-81d6-cbecad5972a2',
    brandLogo:
      'https://d3u7b9fq2opvwp.cloudfront.net/upload-service/168311054832037eeb3c4-c5b5-40ea-8c46-fb0df843a763',
    description: 'Natural smoothies, parfaits, salads, sandwiches and wraps!',
    brandName: 'Good Greens',
    link: GREEN_GOURMET,
  },
  {
    meal: 'http://res.cloudinary.com/cokitchen/image/upload/v1687121133/en33r0svvyixf45jjufk.jpg',
    brandLogo:
      'https://d3u7b9fq2opvwp.cloudfront.net/upload-service/16871008101788de19655-0ea9-457b-8a16-5dbbcdde8c88',
    description: 'Authentic Nigerian breakfast staples ',
    brandName: 'Lagos Breakfast Club',
    link: LAGOS_BREAKFAST_CLUB,
  },
  {
    meal: 'https://d3u7b9fq2opvwp.cloudfront.net/upload-service/1684107002204d81613f1-dd56-48a6-bf69-2ebb17b61291',
    brandLogo:
      'https://d3u7b9fq2opvwp.cloudfront.net/upload-service/168410700350888d9a25d-ff38-4032-a842-e9a32835ef1e',
    description: 'Savor tradition and innovation at Suya Central!',
    brandName: 'Suya Central',
    link: SUYA_CENTRAL,
  },
  {
    meal: 'https://d3u7b9fq2opvwp.cloudfront.net/upload-service/7a483b18-aeb4-4155-9713-cfdb216f56d4:FC%20Pastries12216.jpg',
    brandLogo: Pastry,
    description:
      'Enjoy gourmet pastry bites perfectly crafted by seasoned pastry chefs!',
    brandName: 'Pastry Corner',
    link: PASTRY_CORNER,
  },
  {
    meal: 'https://d3u7b9fq2opvwp.cloudfront.net/upload-service/dafcedce-3ee3-44e9-a9c5-1121c52c85df:FP%20top%20banner2.jpg',
    brandLogo:
      'https://d3u7b9fq2opvwp.cloudfront.net/upload-service/1694380645904ccb76303-7aa1-4248-b2ad-83859e7ed986',
    description: 'Fresh juices and smoothies made from scratch daily',
    brandName: 'Fresh Press',
    link: FRESH_PRESS,
  },
  {
    meal: 'https://d3u7b9fq2opvwp.cloudfront.net/upload-service/1f29dfd3-909f-444f-9207-91258e4218fa:FC%20NT18812-min.jpg',
    brandLogo:
      'https://d3u7b9fq2opvwp.cloudfront.net/upload-service/13255dd7-907f-43e0-80c1-8c087bd18b99:WhatsApp%20Image%202023-11-13%20at%2016.39.57_e8741947.jpg',
    description: 'Your favorite FoodCourt restaurant meals at night',
    brandName: 'The Night Train',
    link: THE_NIGHT_TRAIN,
  },
  {
    meal: Redpepper,
    brandLogo: Red,
    description:
      'A colorful blend of Naija staples from rice meals to mouthwatering grilled eats.',
    brandName: 'Red Pepper',
    link: RED_PEPPER,
  },
  {
    meal: 'https://res.cloudinary.com/olimeed/image/upload/v1659957746/ezgif.com-gif-maker_2_udinxn.jpg',
    brandLogo:
      'https://res.cloudinary.com/olimeed/image/upload/v1659957907/Wrap_City_Logo_2_gq3nds.png',
    description: 'Delicious shawarma, wraps & more!',
    brandName: 'Wrap City',
    link: WRAP_CITY,
    noIg: true,
  },
];

export {
  FeatureContentArray,
  BrandsImage,
  Socials,
  FaqArray,
  TestimonialArray,
  BrandsArray,
  Lgas,
};
