/** @format */

import React from "react";
import Slider from "react-slick";
import BrandCard from "../../components/card/BrandCard";
import { BrandsArray } from "../../constants/arrays";
import { ReactComponent as LeftArrow } from "../../assets/img/arrow-circle-left.svg";
import { ReactComponent as RightArrow } from "../../assets/img/arrow-circle-right.svg";

const MobileCarousel = () => {
	var settings = {
		dots: false,
		infinite: true,
		autoplay: true,
		arrows: true,
		autoplaySpeed: 3000,
		speed: 600,
		slidesToShow: 1,
		swipe: true,
		nextArrow: <RightArrow />,
		prevArrow: <LeftArrow />,
	};
	return (
		<div className="relative brand-image mx-auto w-[100%] h-[350px] xs:h-[280px] flex justify-center items-center md:h-[700px]">
			<Slider {...settings}>
				{BrandsArray.map((brand, index) => {
					return (
						<BrandCard
							meal={brand.meal}
							brandLogo={brand.brandLogo}
							description={brand.description}
							brandName={brand.brandName}
							link={brand?.link}
							className={" mx-auto"}
						/>
					);
				})}{" "}
			</Slider>
		</div>
	);
};

export default MobileCarousel;
