/** @format */

import React from "react";
import FeaturesCard from "../../components/card";
import { FeatureContentArray } from "../../constants/arrays";
import ZigZag from "../../assets/img/zigzag-line.webp";
import BrandImages from "./BrandImages";
import Button from "../../components/button";
import { Link } from "react-router-dom";
import { BRANDS } from "../../routes";

const FeatureContent = () => {
	return (
		<div className="py-10 sm:py-28 ">
			<div className="flex flex-wrap justify-center sm:justify-around px-4 gap-16 sm:gap-6">
				{FeatureContentArray.map((feature, i) => {
					return (
						<FeaturesCard
							featureImg={feature.image}
							title={feature.title}
							details={feature.details}
							delay={feature.delay}
						/>
					);
				})}{" "}
			</div>

			<img
				src={ZigZag}
				alt="line"
				className="py-20 w-[80%] xs:w-[60%] mdLg:w-[40%] mx-auto"
			/>

			<div className="md:space-y-14 sm:pt-10">
				<h3 className="text-center text-grey-100 text-2xl md:text-3xl font-noirbold pb-4">
					Meet the restaurants
				</h3>

				<div className="w-[85%] xs:w-full mx-auto mt-8 md:mt-10">
					<BrandImages />
				</div>
			</div>

			<Link to={BRANDS}>
				<Button
					isTransparent
					isRounded
					height={"h-6 sm:h-11 "}
					text={"Explore all restaurants"}
					className={
						" text-sm sm:text-lg px-5 sm:px-9 mx-auto hover:drop-shadow-xl border-[2px]  my-20 md:my-0"
					}
				/>
			</Link>
		</div>
	);
};

export default FeatureContent;
