/** @format */
import LBC1 from '../assets/img/FC_Naija-Breakfast-Club3241.jpg';
import LBC2 from '../assets/img/FC Naija Breakfast Club3248.jpg';
import LBC3 from '../assets/img/FC Naija Breakfast Club3255.jpg';
import LBC4 from '../assets/img/FC Naija Breakfast Club3257.jpg';
import LBC5 from '../assets/img/FC Naija Breakfast Club3397.jpg';
import LBC6 from '../assets/img/FC Naija Breakfast Club3221.jpg';
export const LagosBreakfastClubBrandArray = [
  {
    meal: LBC1,
    description: 'Deep fried bean cakes served with side of your choice ',
    brandName: 'Akara Breakfast',
    restaurant: 'Lagos Breakfast Club',
    link: 'https://link.getfoodcourt.com/brands/IlZfQrgQXZ8wHJ/vKW8dSBNP7e2Z31ab4dBOuZqPU7U3VkHVWrhLt56G7QNmR/AV/brand/fAIFQe9CWJ4wGpDve28dHkJB7e2ajwebstVJucqIAbJlVE3XWrhLt56G7QNmR/AV/menu/LFBdQuwRUs4wEMuyKG8dGUgS7ezJ2FKbttcc5JnZU+dlA0XRWrhLt56G7QNmR/AV/share',
  },
  {
    meal: LBC2,
    description:
      'Boiled or fried Yam with sauce of your choice and steamed veg',
    brandName: 'Full Lagos Yam Breakfast ',
    restaurant: 'Lagos Breakfast Club',
    link: 'https://link.getfoodcourt.com/brands/IlZfQrgQXZ8wHJ/vKW8dSBNP7e2Z31ab4dBOuZqPU7U3VkHVWrhLt56G7QNmR/AV/brand/fAIFQe9CWJ4wGpDve28dHkJB7e2ajwebstVJucqIAbJlVE3XWrhLt56G7QNmR/AV/menu/flMIR+4RWMgwHMruKG8dTUFC7beT1AabvIBKvp2PBLIyVULSWrhLt56G7QNmR/AV/share',
  },
  {
    meal: LBC3,
    description:
      'Boiled or fried sweet potato with sauce of your choice and steamed veg',
    brandName: 'Full Lagos Sweet Potato Breakfast',
    restaurant: 'Lagos Breakfast Club',
    link: 'https://link.getfoodcourt.com/brands/IlZfQrgQXZ8wHJ/vKW8dSBNP7e2Z31ab4dBOuZqPU7U3VkHVWrhLt56G7QNmR/AV/brand/fAIFQe9CWJ4wGpDve28dHkJB7e2ajwebstVJucqIAbJlVE3XWrhLt56G7QNmR/AV/menu/IwAOEO8UXM8wTJ27KG8dTxMU7ezI2FOb4YEY7ZvUALZpUkLYWrhLt56G7QNmR/AV/share',
  },
  {
    meal: LBC4,
    description:
      'Boiled or fried Plantain with sauce of your choice and steamed veg',
    brandName: 'Full Lagos Plantain Breakfast',
    restaurant: 'Lagos Breakfast Club',
    link: 'https://link.getfoodcourt.com/brands/IlZfQrgQXZ8wHJ/vKW8dSBNP7e2Z31ab4dBOuZqPU7U3VkHVWrhLt56G7QNmR/AV/brand/fAIFQe9CWJ4wGpDve28dHkJB7e2ajwebstVJucqIAbJlVE3XWrhLt56G7QNmR/AV/menu/KlJaTblHC5swSs26KW8dS0BC7e2Zjgeb5YRKvZ7fB7NoWkeDWrhLt56G7QNmR/AV/share',
  },
  {
    meal: LBC6,
    description: 'Flat fluffy pancakes with infused diced onions and pepper',
    brandName: 'Nigerian Pancake Breakfast',
    restaurant: 'Lagos Breakfast Club',
    link: 'https://link.getfoodcourt.com/brands/IlZfQrgQXZ8wHJ/vKW8dSBNP7e2Z31ab4dBOuZqPU7U3VkHVWrhLt56G7QNmR/AV/brand/fAIFQe9CWJ4wGpDve28dHkJB7e2ajwebstVJucqIAbJlVE3XWrhLt56G7QNmR/AV/menu/LVsMF+4XCM8wEci/em8dTkUV7beajgKbto4dusmOU+A3VBKCWrhLt56G7QNmR/AV/share',
  },
  {
    meal: LBC5,
    description: 'Nigerian fried egg in toasted agege bread',
    brandName: 'Maishayi Bread and Egg Sandwich',
    restaurant: 'Lagos Breakfast Club',
    link: 'https://link.getfoodcourt.com/brands/IlZfQrgQXZ8wHJ/vKW8dSBNP7e2Z31ab4dBOuZqPU7U3VkHVWrhLt56G7QNmR/AV/brand/fAIFQe9CWJ4wGpDve28dHkJB7e2ajwebstVJucqIAbJlVE3XWrhLt56G7QNmR/AV/menu/fFoMR+USCc0wHZzrf28dT0NE7e3PiQKb5YYf5MaNCuI1AE3VWrhLt56G7QNmR/AV/share',
  },
];
