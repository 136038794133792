/** @format */

import React from 'react';
import Footer from '../../components/footer';
import Header from '../../components/header';
import Meta from '../../components/meta/Meta';

const UserPolicy = () => {
  return (
		<div>
			<Meta
				title="FoodCourt - User Policy"
				url="https://www.getfoodcourt.com/userpolicy"
				description="Wrong order delivered. Doorstep deliveries. Missing items. Spilled/ damaged items. Overstretched delivery times (2hrs+). Complaints on food freshness. Customization errors. Refund & Reversal Policy. Get Food On The Island, Food In VI, Food In Lekki - Yes"
			/>
			<div className="bg-gradient ">
				<Header />
				<div className="text-white-100  flex justify-center  py-24 mdLg:pt-28  relative ">
					<div className="w-[95%] md:w-[75%] space-y-10">
						<div className="flex flex-wrap justify-between gap-4 items-center">
							<span className="font-noirbold text-2xl md:text-3xl w-full text-center">
								User Policy
							</span>
						</div>

						<ol className="space-y-4 w-[85%] mx-auto list-disc">
							<p>
								<span className=" font-noirbold">Last updated: 2024-07-05</span>
							</p>
							<p className="text-base md:text-lg">
								We take great pride in serving you & ensuring you get the best
								possible delivery experience from start to finish.
							</p>
							<p className="text-base md:text-lg">
								In order to do this while ensuring equity & fairness we’ve
								highlighted some of our user policies on the following topics.
							</p>
							<p className="text-lg md:text-xl mdLg:text-2xl font-noirbold pt-10">
								Full Refunds are Issued Under the Following Circumstances (Upon
								Confirmation):
							</p>
							<li className="space-y-4">
								<p className="text-base md:text-lg">
									<span className=" font-noirbold">Sold Out Items </span> – When
									an order is placed for items that are unavailable.
								</p>
							</li>
							<li className="space-y-4">
								<p className="text-base md:text-lg">
									<span className=" font-noirbold">Wrong Order Delivered </span>{" "}
									– When a mix-up results in the delivery of incorrect item(s).
									(FoodCourt Wallet Refund)
								</p>
							</li>
							<li className="space-y-4">
								<p className="text-base md:text-lg">
									<span className=" font-noirbold">Missing Items </span> – When
									the order is not fully fulfilled with all paid-for items (only
									for the Missing Item, FoodCourt Wallet Refund)
								</p>
							</li>{" "}
						
							<li className="space-y-4">
								<p className="text-base md:text-lg">
									<span className=" font-noirbold">Undelivered Orders </span> –
									When the delivery process is not completed.
								</p>
							</li>{" "}
						
							<li className="space-y-4">
								<p className="text-base md:text-lg">
									<span className=" font-noirbold">Spilled Orders </span> – When
									packaging is damaged or food is spilled during delivery
									(FoodCourt Wallet Refund)
								</p>
							</li>
							<p className="text-lg md:text-xl mdLg:text-2xl font-noirbold pt-10">
								Refund & Reversal Policy
							</p>
							<li className="space-y-4">
								<p className="text-base md:text-lg">
									Funds deposited by users into their FoodCourt Wallet can not
									be transferred/withdrawn into any Bank Account
								</p>
							</li>
							<li className="space-y-4">
								<p className="text-base md:text-lg">
									Refunds/reversals of N10000 and below will exclusively be
									credited to your FoodCourt Wallet on the same day
								</p>
							</li>
							<li className="space-y-4">
								<p className="text-base md:text-lg">
									Refunds/reversals of N10001 and above can also be credited to
									your FoodCourt Wallet on the same day. However, if you opt for
									a bank account reversal, it will be processed within 5-9
									working days with a flat bank transfer fee of N75 deducted
									from the amount to cover transfer costs.
								</p>
							</li>
							<li className="space-y-4">
								<p className="text-base md:text-lg">
									In the event of a human error or bank mistake resulting in an
									accidental transfer payment, full reversal to your FoodCourt
									Wallet will be executed on the same day for amounts equaling
									N10000 or below. For amounts of N10001 and above, a bank
									account reversal, if requested, will be processed within 5-9
									working days with a flat bank transfer fee of N75 deducted
									from the amount for transfer expenses
								</p>
							</li>
							<li className="space-y-4">
								<p className="text-base md:text-lg">
									In case of payment processor errors, a full reversal to your
									FoodCourt Wallet will be executed on the same day. If a bank
									account reversal is requested, it will be processed within 5-9
									working days with no bank fees charged
								</p>
							</li>
							<li className="space-y-4">
								<p className="text-base md:text-lg">
									If you have any questions, complaints or issues please email
									us{" "}
									<a
										href="mailto:hello@getfoodcourt.com"
										onClick={() =>
											window.rudderanalytics.track(
												"Contact With Us Email Clicked"
											)
										}
										className="cursor-pointer font-noirmedium hover:underline transition-prop"
									>
										hello@getfoodcourt.com
									</a>{" "}
									or visit the help/ support page on the app to see the
									available WhatsApp/ phone lines to speak with us directly.
								</p>
							</li>
							<p className="pt-14 text-base md:text-lg font-noirmedium">
								Thank You!
								<br />
								❤️ FoodCourt
							</p>
						</ol>
					</div>
				</div>
			</div>
			<Footer />
		</div>
	);
};

export default UserPolicy;
