/** @format */

import React from "react";
import BrandLayout from "../../components/brandlayout/BrandLayout";
import { SuyaCentralBrandArray } from "../../constants/SuyaCentralBrandArray";
const SuyaCentral = () => {
  return (
    <BrandLayout
      img={
        "https://d3u7b9fq2opvwp.cloudfront.net/upload-service/1684107002204d81613f1-dd56-48a6-bf69-2ebb17b61291"
      }
      brandName={"Suya Central"}
      igLink="https://www.instagram.com/lagosbreakfastclub"
      brandLongDesc={
        "Savor tradition and innovation at Suya Central! Your one stop restaurant for all things Suya. From spicy grilled meats to juicy wraps and burgers, we’ve got it all!"
      }
      brandArray={SuyaCentralBrandArray}
    />
  );
};

export default SuyaCentral;
