import React, { useEffect, useState } from "react";
import { browserName } from "react-device-detect";
import Footer from "../../components/footer";
import Meta from "../../components/meta/Meta";
import FeatureContent from "./FeatureContent";
import GetApp from "./GetApp";
import HeaderContent from "./HeaderContent";
import Testimonial from "./Testimonial";
const LandingPage = () => {
	const [safari, setSafari] = useState(false);

	useEffect(() => {
		if (browserName.includes("Safari")) {
			setSafari(true);
		} else {
			setSafari(false);
		}
	}, [safari]);
	return (
		<div className=" text-white-100 ">
			<Meta
		title="FoodCourt - your favorite delivered to your doorstep!" 
	    description=" Get your favorite meals and drinks delivered to your doorstep! Order from the best food brands in Lekki, Ikoyi and VI"/>
			<HeaderContent safari={safari} />
			<FeatureContent />
			<Testimonial />
			<GetApp safari={safari} />
			<div className="xs:pt-[10rem]">
				<Footer />
			</div>
		</div>
	);
};

export default LandingPage;
