/** @format */

import React from "react";
import BrandLayout from "../../components/brandlayout/BrandLayout";
import { FreshPressBrandArray } from "../../constants/FreshPressBrandArray";
const FreshPress = () => {
  return (
    <BrandLayout
      img={
        "https://d3u7b9fq2opvwp.cloudfront.net/upload-service/dafcedce-3ee3-44e9-a9c5-1121c52c85df:FP%20top%20banner2.jpg"
      }
      brandName={"Fresh Press"}
      igLink="https://www.instagram.com/chow_asian"
      brandLongDesc={
        "Squeeze the day with fresh juices and smoothies made from scratch daily at Fresh Press!"
      }
      brandArray={FreshPressBrandArray}
    />
  );
};

export default FreshPress;
