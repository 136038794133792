/** @format */

export const IkoyiArray = [
	{
		meal: "http://res.cloudinary.com/cokitchen/image/upload/q_auto:good/v1629810276/optimized_uz2czw.jpg",
		description:
			"Delicious party style smokey jollof rice with choice of protein & sides.",
		brandName: "Smokey Jollof Rice",
		link: "https://link.getfoodcourt.com/brands/IlZfQrgQXZ8wHJ/vKW8dSBNP7e2Z31ab4dBOuZqPU7U3VkHVWrhLt56G7QNmR/AV/brand/eAUKRb9EWpowSp3rLG8dHUVP7ezI1FCbvNRM6ZuJB+doB0fYWrhLt56G7QNmR/AV/menu/I1cPTOpHWZgwTMiydG8dS0RG7e2d2AabsdVJ7s2KUe40BkbQWrhLt56G7QNmR/AV/share",
	},
	{
		meal: "http://res.cloudinary.com/cokitchen/image/upload/q_auto:good/v1659916725/edoe5tplf1qorz7ij0xf.jpg",
		description:
			"Special shawarma loaded with pulled BBQ chicken with veggies & special sauce.",
		brandName: "Pulled BBQ Chicken Shawarma",
		link: "https://link.getfoodcourt.com/brands/IlZfQrgQXZ8wHJ/vKW8dSBNP7e2Z31ab4dBOuZqPU7U3VkHVWrhLt56G7QNmR/AV/brand/KlEIFL4VDs8wSJqzdG8dTElB7beSjVqbs9MauceIUeFjAUeEWrhLt56G7QNmR/AV/menu/eVBfQORHCM8wG5q6L28dRUMW7ezI3FGbso5Ovp7ZB7ZpVETUWrhLt56G7QNmR/AV/share",
	},
	{
		meal: "http://res.cloudinary.com/cokitchen/image/upload/v1629810157/optimized_azdgxj.jpg",
		description: "Choice of 1 or 2 chicken flavours.",
		brandName: "14pc Chicken Box Platter",
		link: "https://link.getfoodcourt.com/brands/IlZfQrgQXZ8wHJ/vKW8dSBNP7e2Z31ab4dBOuZqPU7U3VkHVWrhLt56G7QNmR/AV/brand/KlNdEboXCZQwG56/dG8dH0QT7e3J1VSb4IAb6J2JVuVoBkHRWrhLt56G7QNmR/AV/menu/KQYLRb5AWcgwS8q6fm8dSRMW7bfI2Vub4oNP75vZBe9jW03RWrhLt56G7QNmR/AV/share",
	},
];
