/** @format */

import React from 'react';
import TwoPhones from '../../assets/img/FC_two_phones.webp';
import YC from '../../assets/img/Y_Combinator_logo_text_wordmark.png';
import Header from '../../components/header';
import AppStore from '../../assets/img/app-store.png';
import { Fade } from 'react-reveal';
import Typewriter from 'typewriter-effect';
import GooglePlay from '../../assets/img/googlePlayBadge.png';
const HeaderContent = ({ safari }) => {
  return (
    <div className='bg-gradient'>
      <Header />

      <div className='pt-6 md:pt-16 sm:px-16 flex items-center justify-between flex-col md:flex-row space-y-8 pb-16 md:pb-0'>
        <Fade right distance='150px'>
          <div className=' px-4 md:px-0 pt-20 pb-10 md:pt-36 md:pb-20  flex flex-col space-y-14 w-full md:w-[50%]'>
            <div className='space-y-5 sm:space-y-6'>
              <div className='mt-6'>
                <h3 className='pt-15 sm:pt-0 text-[40px] md:text-[55px] leading-[30px] md:leading-[60px] lg:text-[80px]  lg:leading-[85px] font-noirbold w-[100%]'>
                  <Typewriter
                    options={{
                      strings: ['Good Food', 'Snacks', 'Liquor', 'Essentials'],
                      autoStart: true,
                      loop: true,
                      delay: '100',
                    }}
                  />
                </h3>
                <h3 className='text-[40px] md:text-[55px] md:leading-[60px] lg:text-[80px]  lg:leading-[85px] font-noirbold'>
                  on-demand.
                </h3>
              </div>

              <p className='text-sm sm:text-lg md:text-xl'>
                Delicious meals and drinks delivered to your doorstep!
              </p>
              <div className='flex gap-4 sm:gap-6 pt-6 '>
                {safari ? (
                  <>
                    <a
                      href='https://foodcourt.onelink.me/hRlV/Fcweb'
                      target='_blank'
                      rel='noreferrer'
                      onClick={() =>
                        window.rudderanalytics.track('App Install Click', {
                          app_type: 'ios',
                        })
                      }
                    >
                      <img
                        src={AppStore}
                        alt='Download App Button - Order Food, Grocery, Snacks, Wine, Drinks On The Lagos Island,Ibeju Lekki, Victoria Island'
                        className='hover:scale-110 transition-prop drop-shadow-2xl h-8 sm:h-10'
                      />
                    </a>
                  </>
                ) : (
                  <>
                    <a
                      href='https://foodcourt.onelink.me/hRlV/Fcweb'
                      target='_blank'
                      rel='noreferrer'
                      onClick={() =>
                        window.rudderanalytics.track('App Install Click', {
                          app_type: 'ios',
                        })
                      }
                    >
                      <img
                        src={AppStore}
                        alt='Download App Button - Order Food, Grocery, Snacks, Wine, Drinks On The Lagos Island,Ibeju Lekki, Victoria Island'
                        className='hover:scale-110 transition-prop drop-shadow-2xl h-8 sm:h-10'
                      />
                    </a>
                  </>
                )}

                <a
                  href='https://foodcourt.onelink.me/hRlV/Fcweb'
                  target='_blank'
                  rel='noreferrer'
                  onClick={() =>
                    window.rudderanalytics.track('App Install Click', {
                      app_type: 'Android',
                    })
                  }
                >
                  <img
                    src={GooglePlay}
                    alt='Download App Button - Order Food, Grocery, Snacks, Wine, Drinks On The Lagos Island,Ibeju Lekki, Victoria Island'
                    className='hover:scale-110 transition-prop drop-shadow-2xl  h-8 sm:h-10'
                  />
                </a>
              </div>
            </div>

            <div className=' lg:pt-4'>
              <p className='text-sm'>Backed by</p>
              <img
                src={YC}
                alt=''
                className='w-[120px] mdLg:w-[180px] md:w-[220px] -ml-[6px]'
              />
            </div>
          </div>
        </Fade>

        <div className='w-full md:w-[50%] transition-prop'>
          <Fade left distance='150px'>
            <img
              src={TwoPhones}
              alt='FoodCourt App Interface'
              className='w-[100%] '
            />
          </Fade>
        </div>
      </div>
    </div>
  );
};

export default HeaderContent;
