import React from "react";

const RightArrow = ({ onClick }) => {
	return (
		<svg
			class="arrow w-10 h-10 absolute right-arrow  top-[50%] -translate-y-[50%] right-0 z-10"
			fill="none"
			stroke="currentColor"
			viewBox="0 0 24 24"
			xmlns="http://www.w3.org/2000/svg"
			onClick={onClick}
		>
			<path
				stroke-linecap="round"
				stroke-linejoin="round"
				stroke-width="2"
				d="M9 5l7 7-7 7"
			></path>
		</svg>
	);
};

const LeftArrow = ({ onClick }) => {
	return (
		<svg
			class="arrow w-10 h-10 left-0 absolute -translate-y-[50%] top-[50%] z-10"
			fill="none"
			stroke="currentColor"
			viewBox="0 0 24 24"
			onClick={onClick}
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				stroke-linecap="round"
				stroke-linejoin="round"
				stroke-width="2"
				d="M15 19l-7-7 7-7"
			></path>
		</svg>
	);
};

export { RightArrow, LeftArrow };
