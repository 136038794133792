/** @format */

import React from "react";
import { BiLoaderAlt } from "react-icons/bi";
const Button = ({
	isRounded,
	isWhite,
	isTransparent,
	onClick,
	className,
	text,
	icon,
	withImage,
	height,
	loading,
	disabled,
}) => {
	return (
		<button
			disabled={disabled || loading}
			onClick={onClick}
			className={`cursor-pointer px-6 rounded-full text-manrope ${height || "h-[50px] md:h-[70px]"
				} flex justify-center items-center text-center w-fit  hover:scale-110 transition-prop  drop-shadow-2xl cursor-pointer h-12  hover:-110 transition-prop rounded-md  ${isRounded && "rounded-3xl"
				} ${isTransparent
					? "bg-none text-grey-100 border border-grey-200 hover:bg-peach-100 hover:border-none hover:text-black-100"
					: "bg-white-100"
				}
			${disabled || loading ? "opacity-50 cursor-auto" : ""} ${className}`}
		>
			{loading ? <BiLoaderAlt className="animate-spin mr-1" /> : ""}

			{!withImage && text}
			{withImage && (
				<img
					src={icon}
					alt="Download App Button - Order Food, Grocery, Snacks, Wine, Drinks On The Lagos Island,Ibeju Lekki, Victoria Island"
					className="w-[70%]"
				/>
			)}
		</button>
	);
};

export default Button;
