/** @format */

import React from 'react';
import { Link } from 'react-router-dom';
import Button from '../button';

const BrandCard = ({
  isBrandDetails,
  meal,
  link,
  brandLogo,
  brandName,
  restaurant,
  description,
  className,
}) => {
  return (
    <div
      className={` rounded-2xl w-[270px] xs:w-[350px] 2xl:w-[400px]  text-grey-100  ${className}`}
    >
      <div className='relative '>
        <div className='rounded-t-2xl w-[100%] h-[190px] overflow-hidden '>
          <img
            src={meal}
            alt={`${brandName} - ${description} || Get ${brandName} Delivered In Minutes To Your Doorstep`}
            className='w-[100%]  h-[100%] rounded-t-2xl  transition-prop '
          />
          {!isBrandDetails && (
            <div className='border-3 bg-white-100 rounded-full border-white-100 w-[65px] h-[65px]  absolute z-30 -bottom-6 right-7 flex justify-center items-center'>
              <img
                src={brandLogo}
                alt={`${brandName} - ${description} || Get ${brandName} Delivered In Minutes To Your Doorstep`}
                className=' w-14 h-14 transition-prop'
              />
            </div>
          )}
        </div>
      </div>

      <div
        className={` border  border-grey-70 rounded-[inherit] rounded-t-none flex items-center justify-between px-3  py-6 gap-3 ${
          isBrandDetails ? 'pb-6 h-[130px]' : 'pb-10'
        }`}
      >
        <div className='flex items-center justify-between gap-3   w-full'>
          <div className=' w-full'>
            <p className=' font-noirmedium text-sm sm:text-base text-black-200 py-1 '>
              {brandName}
            </p>
            {!isBrandDetails ? (
              <div className='flex justify-between items-center gap-3 w-full'>
                <p className='text-xs text-black-50 w-1/2 md:w-[70%]'>
                  {description}
                </p>
                <Link
                  to={link}
                  className=' underline uppercase text-sm text-red-400'
                >
                  VIEW MORE
                </Link>{' '}
              </div>
            ) : (
              <p className='text-xs text-black-50 w-[100%] '>{description}</p>
            )}
          </div>
        </div>
        {isBrandDetails ? (
          <a href={link} target='_blank' rel='noreferrer'>
            <Button
              height={'h-8 md:h-10'}
              onClick={() => {
                window.rudderanalytics.track('Order Now Item Clicked', {
                  item_name: brandName,
                  restaurant: restaurant,
                });
              }}
              className={
                ' bg-red-400 text-xs text-white-100 font-noirmedium rounded-[30px] px-3 md:px-4 w-[90px] md:w-[110px] flex items-center justify-center'
              }
              text={'Order now'}
            />{' '}
          </a>
        ) : null}
      </div>
    </div>
  );
};

export default BrandCard;
