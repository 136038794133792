/** @format */

import React from 'react';
import BrandLayout from '../../components/brandlayout/BrandLayout';
import { MamasKitchenBrandArray } from '../../constants/MamasKitchenBrandArray';
const MamasKitchen = () => {
  return (
    <BrandLayout
      img={
        'https://res.cloudinary.com/olimeed/image/upload/q_auto:good/v1666863218/Mama_s_Kitchen_os7mye.png'
      }
      brandName={"Mama's Kitchen"}
      igLink={'https://www.instagram.com/mamas_native/'}
      brandLongDesc={
        'From hot delicious amala and abula to sumptuous pounded yam and egusi soup, to ewa agoyin, ofada rice and many more, your taste buds will remain ever satisfied with dishes from a truly African kitchen! Exclusively on FoodCourt.'
      }
      brandArray={MamasKitchenBrandArray}
    />
  );
};

export default MamasKitchen;
