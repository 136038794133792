/** @format */

export const FreshPressBrandArray = [
  {
    meal: 'http://res.cloudinary.com/cokitchen/image/upload/v1694418727/udgcy8xy2kyzstbr2oxy.jpg',
    description: 'Cold pressed Orange & Pineapple',
    brandName: 'Orange and Pineapple Juice',
    restaurant: 'Fresh Press',
    link: 'https://link.getfoodcourt.com/brands/IlZfQrgQXZ8wHJ/vKW8dSBNP7e2Z31ab4dBOuZqPU7U3VkHVWrhLt56G7QNmR/AV/brand/e1IORb9FU50wGZ/uL28dGkcT7beaj1Cb4NdG6MfZA7FpWxWEWrhLt56G7QNmR/AV/menu/IlFfRe4SW8kwG565KW8dH0JH7e2T2VubsIIc5ZnUC7NpVxWCWrhLt56G7QNmR/AV/share',
  },
  {
    meal: 'http://res.cloudinary.com/cokitchen/image/upload/v1694418750/zfncmco21gigkognfwjn.jpg',
    description: 'Cold pressed Pineapple & Ginger. Aids Digestion.',
    brandName: 'Pineapple and Ginger Juice ',
    restaurant: 'Fresh Press',
    link: 'https://link.getfoodcourt.com/brands/IlZfQrgQXZ8wHJ/vKW8dSBNP7e2Z31ab4dBOuZqPU7U3VkHVWrhLt56G7QNmR/AV/brand/e1IORb9FU50wGZ/uL28dGkcT7beaj1Cb4NdG6MfZA7FpWxWEWrhLt56G7QNmR/AV/menu/K1FZFORFWpkwTc/ofm8dThZF7e2SjwKb4IBL7Z7dAuI0UUzQWrhLt56G7QNmR/AV/share',
  },
  {
    meal: 'http://res.cloudinary.com/cokitchen/image/upload/v1694418831/pbt9pgwn59x3iewsxvm0.jpg',
    description: 'Hibiscus flower juice,  Pineapple. Aids Weight Loss.',
    brandName: 'Zobo ',
    restaurant: 'Fresh Press',
    link: 'https://link.getfoodcourt.com/brands/IlZfQrgQXZ8wHJ/vKW8dSBNP7e2Z31ab4dBOuZqPU7U3VkHVWrhLt56G7QNmR/AV/brand/e1IORb9FU50wGZ/uL28dGkcT7beaj1Cb4NdG6MfZA7FpWxWEWrhLt56G7QNmR/AV/menu/LwEPFu8YXM8wGsrsdW8dRRFG7bSf1Aeb4YRMuMuJBbJlAESAWrhLt56G7QNmR/AV/share',
  },
  {
    meal: 'http://res.cloudinary.com/cokitchen/image/upload/v1694418699/ut2n5c5ulmrxft5mx85z.jpg',
    description: 'Fresh press of Tigernut milk, Cococnut & clove',
    brandName: 'Tigernut Milk ',
    restaurant: 'Fresh Press',
    link: 'https://link.getfoodcourt.com/brands/IlZfQrgQXZ8wHJ/vKW8dSBNP7e2Z31ab4dBOuZqPU7U3VkHVWrhLt56G7QNmR/AV/brand/e1IORb9FU50wGZ/uL28dGkcT7beaj1Cb4NdG6MfZA7FpWxWEWrhLt56G7QNmR/AV/menu/K1sJQu9DC5QwHc+4eG8dTEJB7bfO3FWbsIQa6M/VA+I1UEbQWrhLt56G7QNmR/AV/share',
  },
  {
    meal: 'http://res.cloudinary.com/cokitchen/image/upload/v1694418792/mxl8koehjdasnnfrrfjs.jpg',
    description: 'A blend of watermelon, Yogurt & Pineapple',
    brandName: 'Hydra-Melon smoothie',
    restaurant: 'Fresh Press',
    link: 'https://link.getfoodcourt.com/brands/IlZfQrgQXZ8wHJ/vKW8dSBNP7e2Z31ab4dBOuZqPU7U3VkHVWrhLt56G7QNmR/AV/brand/e1IORb9FU50wGZ/uL28dGkcT7beaj1Cb4NdG6MfZA7FpWxWEWrhLt56G7QNmR/AV/menu/KQcEQ+sQCZ0wHJHuLG8dGUFF7bfP1Vqb5oVH6svcBuZgWhGFWrhLt56G7QNmR/AV/share',
  },
  {
    meal: 'http://res.cloudinary.com/cokitchen/image/upload/v1694418771/wktaughsopq9zps6ty8v.jpg',
    description: 'A blend of Yogurt, Orange &  Tumeric.',
    brandName: 'OJ Smoothie',
    restaurant: 'Fresh Press',
    link: 'https://link.getfoodcourt.com/brands/IlZfQrgQXZ8wHJ/vKW8dSBNP7e2Z31ab4dBOuZqPU7U3VkHVWrhLt56G7QNmR/AV/brand/e1IORb9FU50wGZ/uL28dGkcT7beaj1Cb4NdG6MfZA7FpWxWEWrhLt56G7QNmR/AV/menu/KFJZTegSXJgwHJq8fW8dSRIW7eyZiQCbtoJLucbYAOI1V0XSWrhLt56G7QNmR/AV/share',
  },
];
