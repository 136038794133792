/** @format */

import React from "react";
import BrandLayout from "../../components/brandlayout/BrandLayout";
import { TheNightTrainBrandArray } from "../../constants/TheNightTrainBrandArray";
const TheNightTrain = () => {
  return (
    <BrandLayout
      img={
        "https://d3u7b9fq2opvwp.cloudfront.net/upload-service/1f29dfd3-909f-444f-9207-91258e4218fa:FC%20NT18812-min.jpg"
      }
      brandName={"The Night Train(TNT)"}
      igLink="https://www.instagram.com/chow_asian"
      brandLongDesc={
        "Enjoy a delicious array of all your favorite FoodCourt restaurant meals at night with The Night train"
      }
      brandArray={TheNightTrainBrandArray}
    />
  );
};

export default TheNightTrain;
