/** @format */

import React from 'react';
import Slider from 'react-slick';
import { LeftArrow, RightArrow } from '../../assets/img/fc_icons';
import TestimonialCard from '../../components/testimonial';
import { TestimonialArray } from '../../constants/arrays';

const TestimonialCarousel = () => {
  var settings = {
    dots: false,
    infinite: true,
    autoplay: true,
    arrows: true,
    autoplaySpeed: 4500,
    speed: 600,
    slidesToShow: 1,
    // className: "testimonial",
    // slidesToScroll: 3,
    // fade: true,
    // lazyLoad: "ondemand",
    swipe: true,
    nextArrow: <RightArrow />,
    prevArrow: <LeftArrow />,
  };

  return (
    <div className='absolute left-[50%] -translate-x-[50%] z-[100] w-full md:w-[70%] text-grey-200 rounded-lg top-0'>
      <Slider {...settings}>
        {TestimonialArray.map((item, i) => {
          return (
            <div className='w-full h-[300px] md:h-[400px] rounded-lg z-[100] '>
              <TestimonialCard
                key={i}
                username={item.username}
                review={item.review}
                logo={item.logo}
              />
            </div>
          );
        })}
      </Slider>
    </div>
  );
};

export default TestimonialCarousel;
