/** @format */

export const GreenGourmetBrandArray = [
  {
    meal: 'https://d3u7b9fq2opvwp.cloudfront.net/upload-service/16831105978219e03c4c0-04c5-4e49-99f9-c690f426516a',
    description:
      'Mixed Vegetables, tomatoes, Peas, and Grilled Chicken served with a classic vinaigrette',
    brandName: 'Grilled Chicken Sandwich ',
    restaurant: 'Good Greens',
    link: 'https://link.getfoodcourt.com/brands/IlZfQrgQXZ8wHJ/vKW8dSBNP7e2Z31ab4dBOuZqPU7U3VkHVWrhLt56G7QNmR/AV/brand/K1oKFOsSCZswS528e28dRUVH7beZ2Qeb4YVK5MzfC7RjAxHYWrhLt56G7QNmR/AV/menu/f1YMQOhCC5kwSpC5LG8dS0hD7bee3lqb4tJJupqNVrQyWhGEWrhLt56G7QNmR/AV/share',
  },
  {
    meal: 'https://d3u7b9fq2opvwp.cloudfront.net/upload-service/168312050420492c81549-d89e-46db-b624-d5338f56cf17',
    description:
      'Mixed Leafy Greens,  Avocado, Smoked turkey, Pear, Raisins, and Radish paired with classic vinaigrette',
    brandName: 'Avocado and smoked Turkey Salad',
    restaurant: 'Good Greens',
    link: 'https://link.getfoodcourt.com/brands/IlZfQrgQXZ8wHJ/vKW8dSBNP7e2Z31ab4dBOuZqPU7U3VkHVWrhLt56G7QNmR/AV/brand/K1oKFOsSCZswS528e28dRUVH7beZ2Qeb4YVK5MzfC7RjAxHYWrhLt56G7QNmR/AV/menu/eQFfEO4UUpQwEJ/rL28dTBQS7bSS1AWbs9IY68bZAe9lUheDWrhLt56G7QNmR/AV/share',
  },
  {
    meal: 'https://d3u7b9fq2opvwp.cloudfront.net/upload-service/1683110597671652bd91f-cf2a-4677-b363-bf565809691b',
    description:
      'Mixed leafy greens, Tomatoes, Grapes, and feta cheese with your choice of protein served with our classic vinaigrette.',
    brandName: 'Signature Ceaser Salad',
    restaurant: 'Good Greens',
    link: 'https://link.getfoodcourt.com/brands/IlZfQrgQXZ8wHJ/vKW8dSBNP7e2Z31ab4dBOuZqPU7U3VkHVWrhLt56G7QNmR/AV/brand/K1oKFOsSCZswS528e28dRUVH7beZ2Qeb4YVK5MzfC7RjAxHYWrhLt56G7QNmR/AV/menu/KFJZF+URCZkwGcy/em8dREdF7e3O2Vab54FP7J3UVORgBxLXWrhLt56G7QNmR/AV/share',
  },
  {
    meal: 'https://d3u7b9fq2opvwp.cloudfront.net/upload-service/168311059587986a36604-8eed-43d2-b8a2-deed52a0cd88',
    description:
      'Chicken, lettuce, cheese, eggs, and our sandwich dressing on fresh wheat bread.',
    brandName: 'Classic Club Sandwich ',
    restaurant: 'Good Greens',
    link: 'https://link.getfoodcourt.com/brands/IlZfQrgQXZ8wHJ/vKW8dSBNP7e2Z31ab4dBOuZqPU7U3VkHVWrhLt56G7QNmR/AV/brand/K1oKFOsSCZswS528e28dRUVH7beZ2Qeb4YVK5MzfC7RjAxHYWrhLt56G7QNmR/AV/menu/IgINRuhFU5kwHpDpLG8dTEkR7e2Tj1Wbs4FI5JzVB+dgAxXRWrhLt56G7QNmR/AV/share',
  },
  {
    meal: 'https://d3u7b9fq2opvwp.cloudfront.net/upload-service/16831105978219e03c4c0-04c5-4e49-99f9-c690f426516a',
    description:
      'Beef Suya with fresh lettuce, tomatoes, onions, and suya dressing, served on wheat bread',
    brandName: 'Suya Sandwich',
    restaurant: 'Good Greens',
    link: 'https://link.getfoodcourt.com/brands/IlZfQrgQXZ8wHJ/vKW8dSBNP7e2Z31ab4dBOuZqPU7U3VkHVWrhLt56G7QNmR/AV/brand/K1oKFOsSCZswS528e28dRUVH7beZ2Qeb4YVK5MzfC7RjAxHYWrhLt56G7QNmR/AV/menu/f1YMQOhCC5kwSpC5LG8dS0hD7bee3lqb4tJJupqNVrQyWhGEWrhLt56G7QNmR/AV/share',
  },
  {
    meal: 'https://d3u7b9fq2opvwp.cloudfront.net/upload-service/1683110601538be7e287a-58de-4615-815d-64bc1ab952cf',
    description: 'Tuna, sweet corn, lettuce, and parsley on fresh wheat bread',
    brandName: 'Tuna Sandwich',
    restaurant: 'Good Greens',
    link: 'https://link.getfoodcourt.com/brands/IlZfQrgQXZ8wHJ/vKW8dSBNP7e2Z31ab4dBOuZqPU7U3VkHVWrhLt56G7QNmR/AV/brand/K1oKFOsSCZswS528e28dRUVH7beZ2Qeb4YVK5MzfC7RjAxHYWrhLt56G7QNmR/AV/menu/ewAIRb0RC54wHJ+zL28dGhEW7e3LiVCb5Y5K753aUbZmWkXWWrhLt56G7QNmR/AV/share',
  },
];
