/** @format */
import SC1 from '../assets/img/FC Suya Central0345.jpg';
import SC2 from '../assets/img/FC Suya Central0514.jpg';
import SC3 from '../assets/img/FC Suya Central0347.jpg';
import SC4 from '../assets/img/FC Suya Central0348.jpg';
import SC5 from '../assets/img/FC Suya Central0353.jpg';

export const SuyaCentralBrandArray = [
  {
    meal: SC1,
    description:
      'Our signature tozo beef suya chopped and served with a variety of sides to choose from',
    brandName: 'Beef Suya ',
    restaurant: 'Suya Central',
    link: 'https://link.getfoodcourt.com/brands/IlZfQrgQXZ8wHJ/vKW8dSBNP7e2Z31ab4dBOuZqPU7U3VkHVWrhLt56G7QNmR/AV/brand/f1QLQ75CWJ4wGZu5KW8dRBRD7e3Ij1Ob5YJNvsrYA7YyUUKAWrhLt56G7QNmR/AV/menu/KAAJFO0RUpQwHsi7f28dTBQR7e2ajgKb5oYd6c/fUeBjBEzQWrhLt56G7QNmR/AV/share',
  },
  {
    meal: SC2,
    description:
      'delicious mix of chicken and beef suya in a warm pita bread with veg and special sauce. served with sides of choice',
    brandName: 'Mixed Suya Sharwama ',
    restaurant: 'Suya Central',
    link: 'https://link.getfoodcourt.com/brands/IlZfQrgQXZ8wHJ/vKW8dSBNP7e2Z31ab4dBOuZqPU7U3VkHVWrhLt56G7QNmR/AV/brand/f1QLQ75CWJ4wGZu5KW8dRBRD7e3Ij1Ob5YJNvsrYA7YyUUKAWrhLt56G7QNmR/AV/menu/IlEJEb9FXJUwSp6/K28dHkhE7eyfjVGb4ddMv8bZBbYwA0PVWrhLt56G7QNmR/AV/share',
  },
  {
    meal: SC3,
    description:
      'Juicy boneless chicken pieces coated in suya spice and served with sides of your choice',
    brandName: 'Boneless Chicken Suya ',
    restaurant: 'Suya Central',
    link: 'https://link.getfoodcourt.com/brands/IlZfQrgQXZ8wHJ/vKW8dSBNP7e2Z31ab4dBOuZqPU7U3VkHVWrhLt56G7QNmR/AV/brand/f1QLQ75CWJ4wGZu5KW8dRBRD7e3Ij1Ob5YJNvsrYA7YyUUKAWrhLt56G7QNmR/AV/menu/KlZYFOpADskwGJ29fG8dGUVD7e2Sj1eb5o5Muc7YC+JlARXRWrhLt56G7QNmR/AV/share',
  },
  {
    meal: SC4,
    description:
      'Our classic chicken suya cut as your desired size, served with a variety of tasty sides to choose from',
    brandName: 'Chicken Suya',
    restaurant: 'Suya Central',
    link: 'https://link.getfoodcourt.com/brands/IlZfQrgQXZ8wHJ/vKW8dSBNP7e2Z31ab4dBOuZqPU7U3VkHVWrhLt56G7QNmR/AV/brand/f1QLQ75CWJ4wGZu5KW8dRBRD7e3Ij1Ob5YJNvsrYA7YyUUKAWrhLt56G7QNmR/AV/menu/IgFfQ7gSWZ0wEZDsKW8dH0gU7e2c1ACb5YUa6sfdC+40AUDXWrhLt56G7QNmR/AV/share',
  },
  {
    meal: SC2,
    description:
      'Our signature chicken suya wrapped in a warm pita bread with veg and special sauce. served with sides of choice',
    brandName: 'Chicken Suya Shawarma',
    restaurant: 'Suya Central',
    link: 'https://link.getfoodcourt.com/brands/IlZfQrgQXZ8wHJ/vKW8dSBNP7e2Z31ab4dBOuZqPU7U3VkHVWrhLt56G7QNmR/AV/brand/f1QLQ75CWJ4wGZu5KW8dRBRD7e3Ij1Ob5YJNvsrYA7YyUUKAWrhLt56G7QNmR/AV/menu/KVUOF7oUU5swGsu5em8dSxNF7beY2lSbt4UY6cuIBec0UhDYWrhLt56G7QNmR/AV/share',
  },
  {
    meal: SC5,
    description:
      'Gizzard marinated in suya spices, served with sides of your choice ',
    brandName: 'Gizzard Suya',
    restaurant: 'Suya Central',
    link: 'https://link.getfoodcourt.com/brands/IlZfQrgQXZ8wHJ/vKW8dSBNP7e2Z31ab4dBOuZqPU7U3VkHVWrhLt56G7QNmR/AV/brand/f1QLQ75CWJ4wGZu5KW8dRBRD7e3Ij1Ob5YJNvsrYA7YyUUKAWrhLt56G7QNmR/AV/menu/I1MOR70WCZ0wGpyydG8dRUFH7bfI3VKbsIRG7MzbUbFnVUOCWrhLt56G7QNmR/AV/share',
  },
];
