/** @format */

import React,{useState} from "react";
import Header from "../header";


const LocationLayoutHeader = ({ locationName, LocationBg, locationDesc }) => {
	const [showMore, setShowMore]= useState(true);
	const toggleShowMore = () => {setShowMore(!showMore)};

	return (
		<div>
			<div
				className=" h-[500px] md:[550px] lg:h-[650px] bg-black-100 bg-opacity-75">
				<Header />
				<div className="flex flex-col justify-center items-center relative h-full ">
					<div
						className="absolute w-full h-full -z-20 top-0 left-0"
						style={{
							backgroundImage: `url('${LocationBg}')`, backgroundSize: "cover",
							backgroundRepeat: "no-repeat",
							backgroundPosition: "center",
						}} />
					<div className="text-center gap-6 w-[70%] flex flex-col justify-center items-center mx-auto leading-5 z-50 ">
						<h3 className=" text-xl sm:text-2xl lg:text-[60px] bold text-white-100 md:leading[1rem] lg:leading-[4rem]">
							Order food in <br /> {locationName}, Lagos
						</h3>
						
						<p className=" text-white-700 text-sm md:text-base lg:text-lg md:px-16 ">
						{showMore ? locationDesc.replace(/^(.{160}[^\s]*).*/, "$1") : locationDesc}
						<span onClick={toggleShowMore} className=" text-sm text-red-600 hover:text-white-100 cursor-pointer">
        {showMore ? "  ...Show more" : "    Show less"}
      </span>
						</p>
					</div>
				</div>

			</div>

		</div>
	);
};

export default LocationLayoutHeader;
