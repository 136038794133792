/** @format */

import React from 'react';
import BrandLayout from '../../components/brandlayout/BrandLayout';
import { SweetTreatsBrandArray } from '../../constants/SweetTreatsBrandArray';

const SweetTreats = () => {
  return (
    <BrandLayout
      img={
        'https://res.cloudinary.com/olimeed/image/upload/q_auto:good/v1666863214/Sweet_treats_l5bbfv.png'
      }
      brandName={'Sweet Treats'}
      igLink=''
      brandArray={SweetTreatsBrandArray}
      brandLongDesc={
        'Sweet tooth? Say no more! Satisfy those cravings with flavorsome treats of french toast, waffles, pancakes and crepes dazzled with your favorite fruit and syrup toppings! Exclusively on FoodCourt.'
      }
      noIg
    />
  );
};

export default SweetTreats;
